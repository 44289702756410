import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Tooltip, useTheme } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { JobInfoJobFish, PlanningJobInfo } from "../misc/LogitarTypes";
import { format, parse, subMinutes } from "date-fns";
import { Close, ListAlt, Save } from "@mui/icons-material";

type FishStatus = {
    id?: number,
    jobFish?: number,
    status: FishStatusOptions,
    notes: string | null,
    dateTime?: string | null,
};

type Status = "good" | "ok" | "bad";
type FishStatusOptions = Status | null;

const fishTimeReqs = ["reqLoadTime", "reqUnloadTime", "reqWashTime"];

export const fishStatusLabels: { [Property in Status]: string } = {
    good: "Hyvä",
    ok: "Kohtalainen",
    bad: "Huono",
}

export default function DriverFishFields(props: {
    requires: {
        [key: string]: { inJob: string, label: string, billingReason?: number, step?: number, fish?: boolean }
    },
    field: string,
    job: PlanningJobInfo & { slot: number },
    handleJobInfo: (event: { target: { value: string | number | FishStatus[], name: string } }, job: any, update?: boolean) => void,
    pendingFields: Set<string> | undefined,
    fieldPendingProps: (pendingFields: Set<string> | undefined, field: string | string[], sx?: SxProps) => {
        endAdornment: undefined | ReactNode,
    },
    failedFields: Set<string> | undefined,
    fieldFailedProps: (failedFields: Set<string> | undefined, field: string | string[], theme: Theme) => {
        error: undefined | boolean,
        style: undefined | CSSProperties,
    },
    disabled?: boolean,
}) {
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [newFishStatus, setNewFishStatus] = useState<FishStatus>({ status: null, notes: "" });
    const [prevStatusLength, setPrevStatusLength] = useState(Array.isArray(props.job.jobFish.fishStatus) ? props.job.jobFish.fishStatus?.length : 0);

    const theme = useTheme();

    const inJob = props.requires[props.field].inJob;

    const handleTimeJobInfo = (value: Date | null, name: string, update?: boolean) => {
        if (value == null || !value.getTime()) return;
        const sendValue = format(value, "HH:mm:ss");
        const sendEvent = { target: { value: sendValue, name: name } }
        props.handleJobInfo(sendEvent, props.job, update);
    }

    const handleFishStatusJobInfo = () => {
        if (newFishStatus.status) {
            const sendEvent = { target: { value: [newFishStatus], name: "fishStatus" } };
            props.handleJobInfo(sendEvent, props.job, true);
        }
    }

    // If the fishStatus array length increases, sending it was successful and the input fields can be reset
    useEffect(() => {
        if (Array.isArray(props.job.jobFish.fishStatus) && props.job.jobFish.fishStatus?.length > prevStatusLength) {
            setPrevStatusLength(props.job.jobFish.fishStatus.length);
            setNewFishStatus({ status: null, notes: "" });
        }
    }, [props.job.jobFish.fishStatus])

    if (props.requires[props.field].fish) {
        if (fishTimeReqs.includes(props.field)) {
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box sx={{
                        "& .MuiFormControl-root": { width: "7.5rem !important" },
                        "& .MuiTypography-root ": { mr: 0 },
                        "& .MuiInputAdornment-positionEnd": { mr: "12px", ml: 0 }
                    }}>
                        <TimePicker
                            label={props.requires[props.field].label}
                            value={
                                parse(props.job.jobFish[inJob as keyof JobInfoJobFish] as string, "HH:mm:ss", new Date()).getTime() ?
                                    parse(props.job.jobFish[inJob as keyof JobInfoJobFish] as string, "HH:mm:ss", new Date()) :
                                    null
                            }
                            slotProps={{
                                textField: {
                                    name: inJob,
                                    error: props.fieldFailedProps(props.failedFields, inJob, theme).error || false,
                                    InputProps: props.fieldPendingProps(props.pendingFields, inJob).endAdornment ? {
                                        endAdornment: props.fieldPendingProps(props.pendingFields, inJob).endAdornment,
                                        sx: { pr: 0 },
                                    } : {
                                        sx: { pr: 0 },
                                    },
                                    inputProps: { style: props.fieldFailedProps(props.failedFields, inJob, theme).style },
                                    size: "small",
                                    onBlur: (e) => props.handleJobInfo({ ...e, target: { name: e.target.name, value: `${e.target.value}:00` } },
                                        props.job, true),
                                },
                                openPickerButton: { tabIndex: -1 },
                            }}
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            ampm={false}
                            minutesStep={15}
                            skipDisabled
                            maxTime={new Date(0, 0, 0, 8, 59)}
                            onChange={(v) => handleTimeJobInfo(v, inJob)}
                            onAccept={(v) => handleTimeJobInfo(v, inJob, true)}
                            defaultValue={new Date(0, 0, 0, 0, 0)}
                            disabled={props.disabled}
                        />
                    </Box>
                </LocalizationProvider>
            )
        }
        else if (props.field === "reqWashLocation") {
            return (
                <TextField
                    disabled={props.disabled}
                    name={inJob}
                    label={props.requires[props.field].label}
                    sx={{ minWidth: "200px" }}
                    value={props.job.jobFish[inJob as keyof JobInfoJobFish]}
                    onChange={(event) => props.handleJobInfo(event, props.job)}
                    onBlur={(event) => props.handleJobInfo(event, props.job, true)}
                    size="small"
                    inputProps={{ style: props.fieldFailedProps(props.failedFields, inJob, theme).style, maxLength: 100 }}
                    InputProps={props.fieldPendingProps(props.pendingFields, inJob)}
                    error={props.fieldFailedProps(props.failedFields, inJob, theme).error}
                />
            )
        }
        else if (props.field === "reqFishStatus") {
            return (
                <Box>
                    <Button
                        color="inherit"
                        variant="outlined"
                        size="small"
                        startIcon={<ListAlt />}
                        sx={{ mr: 3, mb: 2 }}
                        onClick={() => setShowStatusModal(true)}
                    >
                        <span style={{ marginTop: 2 }}>
                            Kalojen kunto
                        </span>
                    </Button>
                    <Dialog open={showStatusModal} onClose={() => setShowStatusModal(false)} maxWidth={"md"}>
                        <DialogTitle>{`Kuorma ${props.job.slot + 1} – Kalojen kunto`}</DialogTitle>
                        {/* <IconButton
                            onClick={() => setShowStatusModal(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                            }}
                        >
                            <Close />
                        </IconButton> */}
                        <DialogContent>
                            <TableContainer>
                                <Table sx={{ "& .MuiTableCell-root": { p: 1 } }} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Aikaleima</TableCell>
                                            <TableCell>Kalojen kunto</TableCell>
                                            <TableCell>Huomiot</TableCell>
                                            <TableCell>Tallenna</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.job.jobFish.fishStatus?.map((status, key) =>
                                            <TableRow key={key}>
                                                <TableCell>{status.dateTime && format(subMinutes(new Date(status.dateTime), new Date().getTimezoneOffset()), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                                                <TableCell>{status.status && fishStatusLabels[status.status]}</TableCell>
                                                <TableCell>{status.notes && status.notes}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell sx={{ width: "9.5rem" }}></TableCell>
                                            <TableCell>
                                                <Select
                                                    size="small"
                                                    sx={{ width: "8.5rem" }}
                                                    onChange={(e) => setNewFishStatus(prev => ({ ...prev, status: e.target.value as FishStatusOptions }))}
                                                    value={newFishStatus.status ? newFishStatus.status : ""}
                                                    disabled={props.disabled}
                                                >
                                                    <MenuItem value="good">{fishStatusLabels.good}</MenuItem>
                                                    <MenuItem value="ok">{fishStatusLabels.ok}</MenuItem>
                                                    <MenuItem value="bad">{fishStatusLabels.bad}</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    size="small"
                                                    sx={{ width: "14rem" }}
                                                    onChange={(e) => setNewFishStatus(prev => ({ ...prev, notes: e.target.value }))}
                                                    value={newFishStatus.notes}
                                                    disabled={props.disabled}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Tallenna uusi rivi">
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => handleFishStatusJobInfo()}
                                                        disabled={props.disabled || props.pendingFields?.has(inJob)}
                                                    >
                                                        {props.pendingFields?.has(inJob) ?
                                                            <CircularProgress size={24} /> :
                                                            <Save />
                                                        }
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={() => setShowStatusModal(false)}>
                                Sulje
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )
        }
    }

    return null;
}
