import { Info } from "@mui/icons-material";
import { Box, SxProps, Tooltip, useTheme } from "@mui/material";

type ColorInfoProps = {
    colors: {
        class: string,
        info: string,
    }[]
    sx?: SxProps,
}

export default function ColorInfo(props: ColorInfoProps) {
    const theme = useTheme();

    if (!props?.colors?.length) return null;

    const t = 1 / props.colors.length;

    return (
        <Tooltip title={
            <Box>
                {props.colors.map((c, i) =>
                    <Box key={i} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1, py: 0.5 }}>
                        <Box className={c.class}
                            sx={{ width: "1rem", height: "1rem", outline: "1px solid", outlineColor: theme.palette.text.primary }}
                        />
                        {c.info}
                    </Box>
                )}
            </Box>
        }>
            <Box sx={{ width: "1.5rem", height: "1.5rem", position: "relative", background: theme.palette.text.primary, borderRadius: "100%", ...props.sx }}>
                {props.colors.map((c, i) =>
                    <Info key={i} sx={{ position: "absolute" }} className={c.class} style={{
                        background: "transparent",
                        maskImage: i === 0 ? undefined :
                            `conic-gradient(from ${(i - 1) * t}turn, #0000 ${t}turn, #FFF ${t}turn ${t + t}turn, #0000 ${t + t}turn)`
                    }} />
                )}
            </Box>
        </Tooltip>
    )
}