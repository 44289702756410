import { Close, DeleteForever } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LogitarApi from "../../api/LogitarApi";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { fuelTypeList } from "../FuelPriceCard";

type AverageSummary = {
    createdAt: string,
    itemCount: number,
    fuelTypes: string,
    dateStart: string,
    dateEnd: string,
    totalJobs: number
}

export default function EmissionAveragesManager(props: { open: boolean, onClose: () => void }) {
    const [averages, setAverages] = useState<AverageSummary[]>([]);
    const [status, setStatus] = useState<'idle' | 'fetching' | 'failed'>('idle');
    const [avgToDelete, setAvgToDelete] = useState<AverageSummary | null>(null);

    const fetchAverages = () => {
        setStatus('fetching');
        LogitarApi.getEmissionsAverages("summary").then(res => {
            const typedRes = res as { result: AverageSummary[], status: boolean };
            setAverages(typedRes.result);
            setStatus('idle');
        }).catch((err) => {
            console.error(err);
            setStatus('failed')
        })
    }

    const deleteAverage = (avg: AverageSummary | null) => {
        if (avg === null) return;
        setAvgToDelete(null);
        setStatus("fetching");
        LogitarApi.setEmissionsAverages([{
            createdAt: avg.createdAt,
            dateStart: avg.dateStart,
            dateEnd: avg.dateEnd,
        }], true).then(res => {
            const typedRes = res as { status: boolean };
            if (typedRes.status) {
                enqueueSnackbar("Keskiarvot poistettu", { variant: "success" });
                fetchAverages();
            }
        }).catch(err => {
            console.error(err);
            enqueueSnackbar("Keskiarvojen poisto epäonnistui", { variant: "error" });
        })
    }

    useEffect(() => {
        if (props.open) fetchAverages();
    }, [props.open]);

    return (
        <>
            <Dialog
                open={props.open}
                onClose={() => props.onClose()}
                maxWidth="lg"
            >
                <DialogTitle>
                    Hallitse keskiarvolaskentoja
                    <IconButton
                        sx={{ position: "absolute", top: 8, right: 8 }}
                        onClick={() => props.onClose()}
                    ><Close /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ position: "relative" }}>
                        {status === "fetching" &&
                            <Box sx={{
                                position: "absolute", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1,
                                display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0008"
                            }}>
                                <Stack direction={"row"} gap={2}>
                                    <CircularProgress />
                                    <Typography variant="h6">Ladataan keskiarvoja</Typography>
                                </Stack>
                            </Box>}
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Laskettu</TableCell>
                                    <TableCell>Nimikkeitä</TableCell>
                                    <TableCell>Käyttövoimat</TableCell>
                                    <TableCell>Aikaväli</TableCell>
                                    <TableCell>Kuormia</TableCell>
                                    <TableCell>Toiminnot</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {averages.map((avg, i) => {
                                    const fuelTypes = avg.fuelTypes.split(",").map(v => Number(v));
                                    return <TableRow key={i}>
                                        <TableCell>{format(new Date(avg.createdAt + "Z"), "dd.MM.yyyy HH:mm:ss")}</TableCell>
                                        <TableCell>{avg.itemCount}</TableCell>
                                        <TableCell>
                                            {fuelTypeList.filter(ft => fuelTypes.some(v => v === ft.value)).map(ft => ft.label).join(", ")}
                                        </TableCell>
                                        <TableCell>{format(new Date(avg.dateStart), "dd.MM.yyyy")} – {format(new Date(avg.dateEnd), "dd.MM.yyyy")}</TableCell>
                                        <TableCell>{avg.totalJobs}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Poista" disableInteractive>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => setAvgToDelete(avg)}
                                                ><DeleteForever /></Button>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                {averages.length < 1 &&
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography sx={{ my: 1, fontSize: "0.9rem" }}>Ei laskettuja keskiarvoja</Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => props.onClose()}
                    >Sulje</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={avgToDelete !== null} onClose={() => setAvgToDelete(null)}>
                <DialogTitle>
                    Haluatko poistaa keskiarvolaskennan?
                </DialogTitle>
                <DialogContent>
                    {avgToDelete && <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 1 }}>
                            <span>Laskettu:</span><span>{format(new Date(avgToDelete.createdAt + "Z"), "dd.MM.yyyy HH:mm:ss")}</span>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 1 }}>
                            <span>Nimikkeitä:</span><span>{avgToDelete.itemCount}</span>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 1 }}>
                            <span>Aikaväli:</span><span>{format(new Date(avgToDelete.dateStart), "dd.MM.yyyy")} – {format(new Date(avgToDelete.dateEnd), "dd.MM.yyyy")}</span>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", gap: 1 }}>
                            <span>Kuormia:</span><span>{avgToDelete.totalJobs}</span>
                        </Box>
                    </Box>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setAvgToDelete(null)}>Peruuta</Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteForever />}
                        onClick={() => deleteAverage(avgToDelete)}
                    >
                        Poista
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}