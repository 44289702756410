import { Clear, Delete, Save } from '@mui/icons-material';
import { Button, IconButton, MenuItem, Select, SvgIcon, TableCell, TableRow, TextField, Tooltip } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays, addMonths, format, getDay, intervalToDuration, isBefore, set, subDays } from 'date-fns';
import { fi } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { memo, useEffect, useRef, useState } from 'react';
import LogitarApi from '../api/LogitarApi';
import { TipButton, calculateWorkHours, getTime } from '../misc/InternalFeatures';
import LogiTarUser from '../misc/User';
import ReasonInput from './WorkHoursReasonInput';
import Config from '../config/Config';
import AlertPop from './AlertPop';

const days = ["SU", "MA", "TI", "KE", "TO", "PE", "LA"];

//TODO: Different exceptionOptions could be implemented in config directly?
const exceptionOptionsKonne = [
    { header: "Pekkanen", name: "shortTime", value: 8 },
    { header: "Sairas", name: "sickHours", value: 8 },
    { header: "Loma", name: "vacationHours", value: 8 },
    { header: "Vanhempainvapaa", name: "paternityLeaveHours", value: 8 }
]

const exceptionOptionsTark = [
    { header: "Pekkanen", name: "shortTime", value: 8 }
]

const exceptionOptionsAll = {
    "konnekuljetus": exceptionOptionsKonne,
    "tarkkala": exceptionOptionsTark,
    "softrain": exceptionOptionsTark,
    // TODO? is this correct?
    "metsanen": exceptionOptionsKonne
}

const reasonFields = {
    breakHours: "breakChangeReason",
    //waitHours: "waitChangeReason", not used at the moment but is in the database
    startTime: "startChangeReason",
    endTime: "endChangeReason"
}

const bankModeOpts = [

    { id: 2, header: "Pidetty", value: 1 },
    { id: 3, header: "Korvataan", value: 2 }
]

function formTimeTooltip(modDate, reason, originalDate, prevUser) {

    const lines = []

    if (originalDate) {
        lines.push({ title: "Alkuperäinen kirjaus:", val: format(originalDate, "HH:mm", { locale: fi }) })
    }

    if (modDate) {
        lines.push({ title: "Edellinen kirjaus:", val: format(modDate, "HH:mm", { locale: fi }) })
    }

    if (reason) {
        lines.push({ title: "Muokkauksen syy:", val: reason })
    }

    if (prevUser) {
        lines.push({ title: "Muokkaaja:", val: `${prevUser.id}:${prevUser.name}` })
    }

    let tooltipHTML =
        <>
            <table>
                <tbody>
                    {lines.map((l, i) => {
                        return <tr style={{fontSize: 14}} key={i}><td>{l.title}</td><td><b>{l.val}</b></td></tr>
                    })}
                </tbody>
            </table>
        </>

    return lines.length > 0 ? tooltipHTML : null
}

function formWaitTooltip(oldTimeDec, reason) {

    const lines = []

    if (oldTimeDec !== null && !isNaN(Number(oldTimeDec))) {
        lines.push({ title: "Vanha aika:", val: `${oldTimeDec} min` })
    }

    if (reason) {
        lines.push({ title: "Muokkauksen syy:", val: reason })
    }

    let tooltipHTML =
        <>
            {lines.map((l, i) => {
                return <><span style={{fontSize: 14}} key={i}>{l.title + "\t"} <b>{l.val}</b></span><br /></>
            })}
        </>

    return lines.length > 0 ? tooltipHTML : null
}

function WorkHourRow({ wt, ticks, divide, user, disable, updateTotals, ...rest }) {

    const [workRow, setWorkRow] = useState({ ...wt })
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [exceptionType, setExceptionType] = useState(false)
    const modTimes = useRef({ startTime: null, endTime: null, breakHours: null })
    const rowChanged = useRef(false)
    const [ready, setReady] = useState(false)
    const [deleteMode, setDeleteMode] = useState(false)
    const changedReasonValues = useRef(new Set())
    const exceptionOptions = exceptionOptionsAll[Config.getBranch()]
    const [changedTimeFields, setChangedTimeFields] = useState({ startTime: false, endTime: false, break: false });
    const refWt = useRef(wt); // Reference used to detect changes
    const userCostCentreOnException = Config.getBranch() === "tarkkala" || Config.getBranch() === "softrain"

    const addChangedReasonVal = (field) => {
        if (Object.keys(reasonFields).includes(field)) {
            changedReasonValues.current.add(field)
        }
    }

    useEffect(() => {
        let newRow = { ...wt }

        setChangedTimeFields({ startTime: false, endTime: false, break: false });

        // Save original times first to a ref
        modTimes.current.startTime = newRow.startTime
        modTimes.current.endTime = newRow.endTime
        modTimes.current.breakHours = newRow.breakHours

        let exception = false

        //Set correct exception type if found
        exceptionOptions.forEach((o, i) => {
            if (Number(newRow[o.name]) > 0) {
                exception = o
            }
        })

        refWt.current = wt
        setExceptionType(exception)
        setWorkRow(newRow)
    }, [ticks])

    //Setting ready if all reason fields are filled if the value has been changed
    useEffect(() => {

        if (!rowChanged.current) {
            setReady(false)
            return
        }

        if (exceptionType) {
            setReady(true)
            return
        }

        let ready = false

        const skipFields = [
            "id",
            "colorStyle",
            "date",
            "user",
            "vehicle",
            "shift",
            "startTimeMod",
            "endTimeMod",
            "breakHoursMod",
            "startTime",
            "endTime",
            "breakHours",
        ]

        const readyCountTotal = Array.from(changedReasonValues.current).length
        let readyCount = 0

        //Check reason fields
        for (let i = 0; Object.keys(reasonFields).length > i; i++) {

            let field = Object.keys(reasonFields)[i]

            if (!changedReasonValues.current.has(field)) {
                continue
            }

            if (workRow[reasonFields[field]] && workRow[reasonFields[field]].length > 0) {
                readyCount++
            }
        }

        if (readyCountTotal !== readyCount) {
            setReady(false)
            return
        }

        //Check normal fields
        for (let i = 0; Object.keys(workRow).length > i; i++) {

            let field = Object.keys(workRow)[i]

            if (skipFields.includes(field)) {
                continue
            }

            if (workRow[field] !== refWt.current[field]) {
                ready = true
                break
            }
        }

        setReady(ready)


    }, [workRow])

    const onHoursChange = (e) => {

        let newRow = { ...workRow }

        newRow[e.target.name] = e.target.value;
        newRow.colorStyle = { bgcolor: "error.main", color: "white" }
        if (e.target.name === "fromBank" && Number(e.target.value) === 0) {
            newRow.bankMode = 0
        }
        setWorkRow(newRow)
        rowChanged.current = true
    }

    const onWaitChange = (e) => {

        let newRow = { ...workRow }

        if (e.target.name.includes("break")) setChangedTimeFields(prev => ({ ...prev, break: true }));

        //Change to decimal hours

        newRow.colorStyle = { bgcolor: "error.main", color: "white" }
        newRow[e.target.name] = Number(e.target.value) / 60;
        setWorkRow(newRow)
        rowChanged.current = true
        addChangedReasonVal(e.target.name)
    }

    const onTimeValueAccept = (value, name) => {

        console.log(value, name)
        if (value === null) {
            return
        }
        const newRow = { ...workRow }
        rowChanged.current = true
        setChangedTimeFields(prev => ({ ...prev, [name]: true }));

        try {

            newRow[name] = value

            console.log(newRow)

            //Setting the startTime on the same day as the rows date
            newRow.startTime = new Date(new Date(newRow.startTime))
            newRow.startTime.setFullYear(new Date(newRow.date).getFullYear())
            newRow.startTime.setMonth(new Date(newRow.date).getMonth())
            newRow.startTime.setDate(new Date(newRow.date).getDate())

            if (newRow.endTime) {
                newRow.endTime = new Date(newRow.endTime)
                newRow.endTime.setDate(newRow.startTime.getDate())
                newRow.endTime.setMonth(newRow.startTime.getMonth())
                newRow.endTime.setFullYear(newRow.startTime.getFullYear())

                // If dates differ, that means the day is next month.
                if (newRow.endTime.getDate() !== newRow.startTime.getDate()) {
                    newRow.endTime = addMonths(newRow.endTime, 1)
                }
            }

            //Check if time goes over midnight
            if (isBefore(newRow.endTime, newRow.startTime) && newRow.endTime.getDate() === newRow.startTime.getDate()) {
                console.log("Adding day")
                newRow.endTime = addDays(newRow.endTime, 1)

            } else if (newRow.endTime.getDate() !== newRow.startTime.getDate()) {

                if ((newRow.endTime.getHours() === newRow.startTime.getHours() &&
                    newRow.endTime.getMinutes() + 1 > newRow.startTime.getMinutes()) ||
                    newRow.endTime.getHours() > newRow.startTime.getHours()) {

                    console.log("Subbing day")

                    newRow.endTime = subDays(newRow.endTime, 1)
                }
            }

            const interval = { start: new Date(newRow.startTime), end: new Date(newRow.endTime) }

            calculateWorkHours(interval).then((calculatedHours) => {

                newRow.workHours = calculatedHours.workHours
                newRow.eveningHours = Config.getBranch() === "metsanen" ? 0 : calculatedHours.eveningHours
                newRow.nightHours = Config.getBranch() === "metsanen" ? 0 : calculatedHours.nightHours
                newRow.holidayHours = calculatedHours.holidayHours
                newRow.sundayHours = calculatedHours.sundayHours

                newRow.colorStyle = {
                    bgcolor: "error.main",
                    color: "white"
                }

                if (Config.getBranch() === "tarkkala" || Config.getBranch() === "softrain") {
                    newRow.breakHours = newRow.workHours < 8 ? 0 : 0.5
                }

                changedReasonValues.current.add(name)
                setWorkRow(newRow)
            })


        } catch (e) {
            console.error("Interval not ready, saving only the field")
            changedReasonValues.current.add(name)
            setWorkRow(newRow)
        }
    }

    const reasonInputSave = (field, reason) => {
        let newRow = { ...workRow }

        console.log(field, reason)

        if (!(reason === newRow[`${field}ChangeReason`])) {
            newRow.colorStyle = { bgcolor: "error.main", color: "white" }
        }

        newRow[`${field}ChangeReason`] = reason
        rowChanged.current = true
        setWorkRow(newRow)
    }

    const saveWorkTime = (swt) => {

        const sendingWT = { ...swt }

        if (LogiTarUser.current.vehicle && sendingWT.vehicle === null) {
            sendingWT.vehicle = LogiTarUser.current.vehicle.id;
        }

        sendingWT.date = format(new Date(sendingWT.date), 'yyyy-MM-dd')
        sendingWT.startTime = sendingWT.startTime ? format(new Date(sendingWT.startTime), 'yyyy-MM-dd HH:mm:ss') : null
        sendingWT.endTime = sendingWT.endTime ? format(new Date(sendingWT.endTime), 'yyyy-MM-dd HH:mm:ss') : null

        if (sendingWT.startTimeOrig === null) {
            sendingWT.startTimeOrig = format(new Date(sendingWT.startTime), 'yyyy-MM-dd HH:mm:ss')
        } else {
            delete sendingWT.startTimeOrig
        }

        if (sendingWT.endTimeOrig === null) {
            sendingWT.endTimeOrig = format(new Date(sendingWT.endTime), 'yyyy-MM-dd HH:mm:ss')
        } else {
            delete sendingWT.endTimeOrig
        }

        if (Config.getBranch() === "metsanen") {
            //send fromBank as decimal
            sendingWT.fromBank = sendingWT.fromBank 
                ? Number(sendingWT.fromBank.getHours() + sendingWT.fromBank.getMinutes() / 60) 
                : 0
        } else {
            delete sendingWT.fromBank
            delete sendingWT.bankMode
        }


        //Only save modified times if they are different than the original
        //If the modtime start or end is not null, then it means there is an original value set before this
        //If the original value is different than the current value, then set the mod value
        //If the original value is null/not set yet, then just insert a 00:00 time to mod value

        //TODO: maybe refacture this horrible if else tree
        if (new Date(modTimes.current.startTime).getTime() !== new Date(sendingWT.startTime).getTime()) {

            sendingWT.startTimeMod = format(new Date(modTimes.current.startTime), 'yyyy-MM-dd HH:mm:ss')
            sendingWT.startTimeModUser = LogiTarUser.current.info.id
        } else {
            delete sendingWT.startTimeMod
            delete sendingWT.startTimeModUser
        }

        if (new Date(modTimes.current.endTime).getTime() !== new Date(sendingWT.endTime).getTime()) {

            if (modTimes.current.endTime === null) {
                sendingWT.endTimeMod = format(new Date(new Date(sendingWT.endTime).setHours(0, 0, 0, 0)), 'yyyy-MM-dd HH:mm:ss')
            } else {
                sendingWT.endTimeMod = format(new Date(modTimes.current.endTime), 'yyyy-MM-dd HH:mm:ss')
            }

            sendingWT.endTimeModUser = LogiTarUser.current.info.id
        } else {
            delete sendingWT.endTimeMod
            delete sendingWT.endTimeModUser
        }

        if (modTimes.current.breakHours !== sendingWT.breakHours) {

            if (modTimes.current.breakHours === null) {
                sendingWT.breakHoursMod = 0
            } else {
                sendingWT.breakHoursMod = modTimes.current.breakHours
            }
        } else {
            delete sendingWT.breakHoursMod
        }

        console.log(sendingWT)

        LogitarApi.setWorkHours(
            [sendingWT]
        )
            .then((r) => {

                changedReasonValues.current.clear()

                rowChanged.current = false

                refWt.current = sendingWT

                if (!workRow.id) {
                    setWorkRow({ ...swt, id: r.latestID, colorStyle: {} })
                } else {
                    setWorkRow({ ...swt, colorStyle: {} })
                }


                // If there was endTime set
                if (sendingWT.endTime !== null && sendingWT.id !== null) {

                    // Ending the currentShift. If the user is management, then fetch the user
                    if (LogiTarUser.current.isManagement()) {
                        LogitarApi.getUsers({ id: sendingWT.user, extent: "all" }).then((r) => {

                            console.log(r.users[0])
                            if (r.users[0].currentShift === sendingWT.id) {
                                LogitarApi.setUser({ id: sendingWT.user, currentShift: null }).catch(err => console.error(err));
                            }

                        }).catch(err => console.error(err))

                    } else {
                        if (LogiTarUser.current.info.currentShift === sendingWT.id) {
                            LogitarApi.setUser({ id: sendingWT.user, currentShift: null }).catch(err => console.error(err));
                            LogiTarUser.current.info.currentShift = null;
                        }
                    }
                }
                updateTotals()

                modTimes.current.startTime = sendingWT.startTime ? new Date(sendingWT.startTime) : null
                modTimes.current.endTime = sendingWT.endTime ? new Date(sendingWT.endTime) : null

                enqueueSnackbar("Työtunnit tallennettu")
            })
            .catch((e) => {
                console.error(e)
                enqueueSnackbar("Työtuntien tallennus epäonnistui", { variant: "error" })
            })
    }


    const deleteWorkTime = (action, dwt) => {

        if (!action) {
            setDeleteMode(false)
            return
        }

        setReady(false)

        const defaultWT = {
            colorStyle: {
                bgcolor: "error.light",
                color: "white"
            },
            date: dwt.date,
            costCentre: 0,
            startTime: new Date(dwt.date).setHours(0, 0, 0, 0),
            endTime: null,
            startTimeMod: null,
            endTimeMod: null,
            breakHoursMod: null,
            shift: dwt.shift || null,
            workHours: "0.0",
            eveningHours: "0.0",
            nightHours: "0.0",
            breakHours: 0.0,
            waitHours: 0.0,
            shortTime: 0.0,
            sickHours: 0.0,
            vacationHours: 0.0,
            holidayHours: 0.0,
            paternityLeaveHours: 0.0,
            dailyAllowance: 0,
            sundayHours: 0,
            route: "",
            fromBank: null,
            bankMode: 0,
            user: dwt.user,
            vehicle: dwt.vehicle,
            breakChangeReason: null,
            waitChangeReason: null,
            startChangeReason: null,
            endChangeReason: null,
        }

        modTimes.current.startTime = null
        modTimes.current.endTime = null
        modTimes.current.breakHours = null

        setExceptionType(false)

        changedReasonValues.current.clear()
        rowChanged.current = false

        if (!dwt.id) {
            setWorkRow({ ...defaultWT })
            setDeleteMode(false)
            return
        }

        LogitarApi.setWorkHours(dwt, true)
            .then((r) => {
                setWorkRow({ ...defaultWT })
                enqueueSnackbar(`Työtunnit ${format(new Date(dwt.date), "dd.MM.yyyy")} poistettu`)
                setDeleteMode(false)
                refWt.current = defaultWT
            })
            .catch((e) => {
                console.error(e)
                enqueueSnackbar("Työtuntien poisto epäonnistui", { variant: "error" })
                setDeleteMode(false)
            })
    }

    const onExceptionHourChange = (e) => {

        let newRow = { ...workRow }

        newRow.startTime = new Date(newRow.date).setHours(0, 0, 0, 0)
        newRow.endTime = null
        newRow.workHours = "0.0"
        newRow.eveningHours = "0.0"
        newRow.nightHours = "0.0"
        newRow.startChangeReason = null
        newRow.endChangeReason = null
        newRow.breakHours = 0
        newRow.waitHours = 0
        newRow.dailyAllowance = 0
        newRow.fromBank = null
        newRow.bankMode = 0

        //Format all option values when changed 
        exceptionOptions.forEach((o) => {
            newRow[o.name] = 0
        })
        newRow.colorStyle = { bgcolor: "error.main", color: "white" }
        if (userCostCentreOnException && e.target.value.value > 0 && user.costCentre != null) {
            newRow.costCentre = user.costCentre
        }
        rowChanged.current = true
        setExceptionType(e.target.value)
        setWorkRow(({ ...newRow, [e.target.value.name]: e.target.value.value }))
        setReady(true)
    }

    return (
        <TableRow {...rest} sx={divide ? { borderBottom: 4, borderColor: 'green' } : {}}>
            {workRow &&
                <>
                    <TableCell sx={{ ...workRow.colorStyle, textAlign: "center", width: "2rem" }}>{days[getDay(new Date(workRow.date))]}</TableCell>
                    <TableCell sx={{ ...workRow.colorStyle, textAlign: "center", width: "3.5rem" }}>{format(new Date(workRow.date), "dd.MM.")}</TableCell>
                    <Tooltip placement='top' title={workRow.vehicle ? `Kalusto: ${workRow.vehicle}`: null}>
                        <TableCell sx={{ width: "3.5rem" }}>
                            <TextField 
                                name="costCentre" 
                                type="tel" 
                                value={workRow.costCentre} 
                                inputProps={{ max: 254, style: { width: "100%" } }} 
                                onChange={onHoursChange} />
                            </TableCell>
                    </Tooltip>
                    <Tooltip
                        title={formTimeTooltip(workRow.startTimeMod, workRow.startChangeReason, workRow.startTimeOrig, workRow.startTimeModUser)}
                        placement="top"
                        arrow
                        disableInteractive
                    >
                        <TableCell sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: workRow.startTimeMod || changedTimeFields.startTime ? "error.main" : "" }, width: "4.5rem" }}>
                            <ReasonInput
                                info={{
                                    value: workRow.startTime ? format(workRow.startTime, "HH:mm") : "00:00",
                                    field: "start",
                                    reason: workRow.startChangeReason,
                                }}
                                disabled={exceptionType}
                                onClose={reasonInputSave}
                            >

                                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        renderInput={(params) => <TextField {...params} />}
                                        disabled={exceptionType}
                                        name="startTime"
                                        value={workRow.startTime}
                                        autoFocus
                                        onChange={(e, c) => {
                                            if (c.validationError === null)
                                                onTimeValueAccept(e, "startTime")
                                        }}

                                        onAccept={e => {
                                            onTimeValueAccept(e, "startTime")

                                        }}
                                    />
                                </LocalizationProvider>
                            </ReasonInput>
                        </TableCell>
                    </Tooltip>
                    <Tooltip
                        title={formTimeTooltip(workRow.endTimeMod, workRow.endChangeReason, workRow.endTimeOrig, workRow.endTimeModUser)}
                        placement="top"
                        arrow
                        disableInteractive
                    >
                        <TableCell sx={{ "& .MuiOutlinedInput-notchedOutline": { borderColor: workRow.endTimeMod || changedTimeFields.endTime ? "error.main" : "" }, width: "4.5rem" }}>
                            <ReasonInput
                                info={{
                                    value: workRow.endTime ? format(workRow.endTime, "HH:mm") : "00:00",
                                    field: "end",
                                    reason: workRow.endChangeReason,
                                }}
                                disabled={exceptionType}
                                onClose={reasonInputSave}
                            >

                                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        renderInput={(params) => <TextField {...params} />}
                                        name="endTime"
                                        disabled={exceptionType}
                                        value={workRow.endTime}
                                        autoFocus
                                        onChange={(e, c) => {
                                            if (c.validationError === null)
                                                onTimeValueAccept(e, "endTime")
                                        }}
                                        onAccept={e => {
                                            onTimeValueAccept(e, "endTime")

                                        }}
                                    />
                                </LocalizationProvider>
                            </ReasonInput>
                        </TableCell>
                    </Tooltip>
                    <TableCell sx={{ width: "3.5rem" }}>
                        <TextField name="workHours" disabled={true} value={getTime(workRow.workHours)} type={'text'} onChange={onHoursChange} inputProps={{ style: { width: "100%" } }} />
                    </TableCell>
                    {!(Config.getBranch() === "metsanen") &&
                    <>
                        <TableCell sx={{ width: "3.5rem" }}>
                            <TextField name="eveningHours" disabled={true} value={getTime(workRow.eveningHours)} type={'text'} onChange={onHoursChange} inputProps={{ style: { width: "100%" } }} />
                        </TableCell>
                        <TableCell sx={{ width: "3.5rem" }}>
                            <TextField name="nightHours" disabled={true} value={getTime(workRow.nightHours)} type={'text'} onChange={onHoursChange} inputProps={{ style: { width: "100%" } }} />
                        </TableCell>
                    </>
                    }
                    <Tooltip
                        title={formWaitTooltip(
                            workRow.breakHoursMod !== null ? Math.round(workRow.breakHoursMod * 60) : null,
                            workRow.breakChangeReason
                        )}
                        disableInteractive
                        placement="top"
                        arrow>
                        <TableCell sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: workRow.breakHoursMod !== null || changedTimeFields.break
                                    ? "error.main" : ""
                            },
                            width: "6rem"
                        }}>
                            <ReasonInput
                                info={{
                                    value: Math.round(workRow.breakHours * 60),
                                    field: "break",
                                    reason: workRow.breakChangeReason,
                                }}

                                onClose={reasonInputSave}
                            >
                                <TextField
                                    name="breakHours"
                                    value={Math.round(workRow.breakHours * 60)}
                                    type='number'
                                    onChange={onWaitChange}
                                    inputProps={{ style: { width: "100%" } }}
                                />
                            </ReasonInput>
                        </TableCell>
                    </Tooltip>


                    <TableCell sx={{ width: "6rem" }}>
                        <TextField
                            name="waitHours"
                            value={Math.round(workRow.waitHours * 60)}
                            type='number'
                            onChange={onWaitChange}
                            inputProps={{ style: { width: "100%" } }}
                        />
                    </TableCell>

                    {Config.getBranch() === "metsanen" &&
                        <>
                            {/** Insert fromBank hours mui timepicker*/}
                            <TableCell sx={{ width: "4.5rem" }}>
                                <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        renderInput={(params) => <TextField {...params} />}
                                        name="fromBank"
                                        views={['hours', 'minutes']}
                                        value={workRow.fromBank}                                        
                                        onChange={(e, c) => {
                                            console.log(e)
                                            console.log(c)
                                            if (c.validationError !== null) {
                                                return
                                            }
                                            let newRow = { ...workRow }
                                            newRow.fromBank = e
                                            setWorkRow({ ...newRow })
                                            rowChanged.current = true
                                            
                                        }}
                                    ></TimePicker>
                                </LocalizationProvider>
                            </TableCell>
                            {/* Select with bankModeOpts */}
                            <TableCell sx={{ width: "145px" }}>
                                <Select 
                                name="bankMode" 
                                disabled={Number(workRow.fromBank) === 0 || workRow.fromBank === null} 
                                value={workRow.bankMode} 
                                onChange={onHoursChange} 
                                size='small' 
                                sx={{ width: "145px" }}
                                SelectDisplayProps={{ style: { paddingRight: "4px", fontSize: "11pt" }}}
                                    IconComponent={
                                        workRow.bankMode
                                            ? () => (
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        let newRow = { ...workRow }
                                                        newRow.bankMode = 0
                                                        rowChanged.current = true
                                                        setWorkRow({ ...newRow })
                                                    }}
                                                >
                                                    <Clear></Clear>
                                                </IconButton>
                                            )
                                            : undefined
                                    }>
                                    {bankModeOpts.map((option, key) => (
                                        <MenuItem value={option.value} key={option.id + 100}>{option.header}</MenuItem>
                                    ))}
                                </Select>
                            </TableCell>
                        </>
                    }

                    <TableCell sx={{ width: "7rem" }}>
                        <Select name="exceptionHours" sx={{ width: "7rem" }} value={exceptionType} onChange={onExceptionHourChange}
                            size='small'
                            SelectDisplayProps={{ style: { paddingRight: "4px", fontSize: "11pt" } }}
                            IconComponent={
                                exceptionType
                                    ? () => (
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                let newRow = { ...workRow }

                                                //Format all option values when changed 
                                                exceptionOptions.forEach((o) => {
                                                    newRow[o.name] = 0
                                                })
                                                newRow.colorStyle = { bgcolor: "error.main", color: "white" }
                                                rowChanged.current = true
                                                setWorkRow({ ...newRow })
                                                setExceptionType(false)
                                            }}
                                        >
                                            <Clear></Clear>
                                        </IconButton>
                                    )
                                    : undefined
                            }>
                            {exceptionOptions.map((option, key) => (
                                <MenuItem value={option} key={key + option.name}>{option.header}</MenuItem>
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell sx={{ width: "5rem" }}>
                        <TextField select name="dailyAllowance" value={workRow.dailyAllowance} onChange={onHoursChange}
                            SelectProps={{ SelectDisplayProps: { style: { fontSize: "11pt" } } }}
                        >
                            {[{ id: 1, header: "Ei", value: 0 }, { id: 2, header: "Puoli", value: 1 }, { id: 3, header: "Koko", value: 2 }].map((option, key) => (
                                <MenuItem value={option.value} key={option.id + 100}>{option.header}</MenuItem>
                            ))}
                        </TextField>
                    </TableCell>
                    <TableCell >
                        <TextField value={workRow.route} inputProps={{ style: { width: "100%" } }} sx={{ minWidth: 150 }} name="route" onChange={onHoursChange} />
                    </TableCell>


                    <Tooltip disableInteractive placement="top" title={disable ? "Ei aktiivnen palkkajakso" : "Tallenna"}>
                        <TableCell sx={{ width: "5rem" }}>
                            <Button disabled={disable || !ready} onClick={() => saveWorkTime(workRow)} variant="contained">
                                <SvgIcon component={Save} />
                            </Button>
                        </TableCell>
                    </Tooltip>
                    <Tooltip disableInteractive placement="top" title={disable ? "Ei aktiivnen palkkajakso" : "Poista"}>
                        <TableCell sx={{ width: "5rem" }}>
                            <Button disabled={disable} color="error" onClick={() => setDeleteMode(true)} variant="contained">
                                <SvgIcon component={Delete} />
                            </Button>
                        </TableCell>
                    </Tooltip>                    
                </>
            }
            {deleteMode &&
                <AlertPop
                    cancellable={true}
                    onClose={(action) => deleteWorkTime(action, workRow)}
                    title={"Haluatko varmasti poistaa kirjauksen?"}
                    content={`Päivämäärä: ${format(new Date(workRow.date), "dd.MM.yyyy")}`}
                />
            }



        </TableRow >
    )
}

export default memo(WorkHourRow)