import { Box, Button, CircularProgress, Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import { endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import { DateRangeSelect } from "../components/DateSelect";
import { Calculate, EventRepeat, ListAlt, Save } from "@mui/icons-material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { EmissionsAverage } from "../misc/LogitarTypes";
import { fuelTypeEmissions } from "./EmissionReports";
import { FuelType, fuelTypeList } from "../components/FuelPriceCard";
import LogitarApi from "../api/LogitarApi";
import ColorInfo from "../components/ColorInfo";
import EmissionAveragesManager from "../components/emissionreports/EmissionAveragesManager";
import { enqueueSnackbar } from "notistack";
import { useUserConfig } from "../misc/UserConfig";
import { EmissionMultipliersConfig, Multiplier } from "../components/EmissionMultipliers";

enum AvgStatus {
    Idle,
    ItemFetch,
    LastFetch,
    JobFetch,
    AvgCalc,
    Ready,
    Saving,
    Done,
    FetchError,
    SaveError,
}

type InvalidAvgReason = "fuelNull" | "fuelNegative" | "kmNull" | "kmNegative"

type EmissionAvgTableRow = {
    id: string,
    itemId: number,
    name: string,
    fuelType: number,
    jobCount: number,
    lastDates?: [string, string],
    lastTotalKm?: number,
    lastTotalFuel?: number,
    lastTotalEmissions?: number,
    lastJobCount?: number,
    newDates?: [string, string],
    newTotalKm?: number,
    newTotalFuel?: number,
    newTotalEmissions?: number,
    newJobCount?: number,
    diffKm?: number,
    diffFuel?: number,
    diffEmissions?: number,
    diffJobCount?: number,
}

type EmissionAveragesItem = {
    id: number,
    name: string,
    active: number,
    jobCount: number,
    fuelType: number,
}

type EmissionLimitedJob = {
    id: number,
    billingDate: string,
    item: number,
    loadStart: string | null,
    unloadEnd: string | null,
    nextLoadStart: string | null,
    distanceTotal: number | null,
    distanceJob: number | null,
    fuelTotal: number | null,
    fuelJob: number | null,
    fuelType: number | null,
}

const columns: GridColDef[] = [
    { field: "id" },
    { field: "itemId", headerName: "Nimike ID", width: 80 },
    { field: "name", headerName: "Nimike", flex: 3 },
    {
        field: "fuelType", headerName: "Käyttövoima", flex: 1,
        valueFormatter: (params) => fuelTypeList.find(ft => ft.value === params.value)?.label || ""
    },
    { field: "lastDates", headerName: "Ajopvm väli", flex: 1.5, valueFormatter: (params) => dateRangeFormatter(params.value) },
    { field: "lastJobCount", headerName: "Kuormia", flex: 1 },
    { field: "lastTotalKm", headerName: "Km A→B→C", flex: 1 },
    { field: "lastTotalFuel", headerName: "Kulutus", flex: 1 },
    {
        field: "lastTotalEmissions", headerName: "Päästöt", flex: 1,
        valueFormatter: (params) => params.value != null ? Math.round(params.value * 100) / 100 : null
    },
    { field: "newDates", headerName: "Ajopvm väli", flex: 1.5, valueFormatter: (params) => dateRangeFormatter(params.value) },
    { field: "newJobCount", headerName: "Kuormia", flex: 1 },
    { field: "newTotalKm", headerName: "Km A→B→C", flex: 1 },
    { field: "newTotalFuel", headerName: "Kulutus", flex: 1 },
    {
        field: "newTotalEmissions", headerName: "Päästöt", flex: 1,
        valueFormatter: (params) => params.value != null ? Math.round(params.value * 100) / 100 : null
    },
    { field: "diffKm", headerName: "Ero km", flex: 1 },
    { field: "diffFuel", headerName: "Ero kulutus", flex: 1 },
    {
        field: "diffEmissions", headerName: "Ero päästöt", flex: 1,
        valueFormatter: (params) => params.value != null ? Math.round(params.value * 100) / 100 : null
    }
]

const statusSx: SxProps = { display: "inline-flex", flexDirection: "row", alignItems: "baseline", gap: 1 };

export default function EmissionAverages() {
    const multipliersConfig = useUserConfig<EmissionMultipliersConfig>('emissionMultipliers');
    const [fuelMultipliers,] = useState(multipliersConfig?.config?.multipliers ? multipliersConfig.config.multipliers : fuelTypeEmissions);

    const [dateRange, setDateRange] = useState<DateRange<Date>>([
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1))
    ]);
    const [lastDateRange, setLastDateRange] = useState(dateRange);
    const [status, setStatus] = useState<AvgStatus>(AvgStatus.Idle);

    const [items, setItems] = useState<EmissionAveragesItem[]>([]);
    const [jobs, setJobs] = useState<EmissionLimitedJob[]>([]);
    const [lastData, setLastData] = useState<EmissionsAverage[]>([]);
    const [newData, setNewData] = useState<Partial<EmissionsAverage>[]>([]);
    const [tableRows, setTableRows] = useState<EmissionAvgTableRow[]>([]);

    const [showManager, setShowManager] = useState(false);

    useEffect(() => {
        setTableRows(buildAveragesTableRows(items, lastData, newData, fuelMultipliers));
    }, [items, lastData, newData]);

    useEffect(() => {
        if (jobs.length > 0) {
            setStatus(AvgStatus.AvgCalc);
            setNewData(calculateItemAverages(items, jobs, lastDateRange));
            setStatus(AvgStatus.Ready);
        }
    }, [jobs]);

    useEffect(() => {
        if (items.length > 0) {
            fetchLastAverages();
        }
    }, [items]);

    // Do an initial fetch
    useEffect(() => {
        fetchItems();
    }, []);

    const renderStatus = (status: AvgStatus) => {
        switch (status) {
            case AvgStatus.Idle:
                return <Typography sx={statusSx}>Tee laskenta tai vaihda aikaväliä</Typography>;
            case AvgStatus.ItemFetch:
                return <Typography sx={statusSx}><CircularProgress size={"1.1rem"} />Ladataan nimikkeitä...</Typography>;
            case AvgStatus.LastFetch:
                return <Typography sx={statusSx}><CircularProgress size={"1.1rem"} />Ladataan edellisiä keskiarvoja...</Typography>;
            case AvgStatus.JobFetch:
                return <Typography sx={statusSx}><CircularProgress size={"1.1rem"} />Haetaan aikavälin kuormia...</Typography>;
            case AvgStatus.AvgCalc:
                return <Typography sx={statusSx}><CircularProgress size={"1.1rem"} />Lasketaan uusia keskiarvoja...</Typography>;
            case AvgStatus.Ready:
                return <Typography sx={statusSx}>Keskiarvot valmiina tallennettaviksi</Typography>;
            case AvgStatus.Saving:
                return <Typography sx={statusSx}><CircularProgress size={"1.1rem"} />Tallennetaan keskiarvoja...</Typography>;
            case AvgStatus.Done:
                return <Typography sx={{ ...statusSx, color: "success.main" }}>Keskiarvot tallennettu</Typography>;
            case AvgStatus.FetchError:
                return <Typography sx={{ ...statusSx, color: "error.main" }}>Tietojen hakeminen epäonnistui</Typography>;
            case AvgStatus.SaveError:
                return <Typography sx={{ ...statusSx, color: "error.main" }}>Tietojen tallentaminen epäonnistui</Typography>;
            default:
                return null;
        }
    }

    const fetchItems = () => {
        setLastDateRange(dateRange);
        if (dateRange[0] == null || dateRange[1] == null) return;
        setStatus(AvgStatus.ItemFetch);
        LogitarApi.getEmissionsAverages("items", dateRange[0], dateRange[1]).then(res => {
            const typedRes = res as { result: EmissionAveragesItem[], status: boolean };
            setItems(typedRes.result);
            setStatus(AvgStatus.Idle);
        }).catch(err => {
            setStatus(AvgStatus.FetchError);
            console.error(err);
        })
    }

    const fetchJobs = () => {
        setStatus(AvgStatus.JobFetch);
        if (dateRange[0] == null || dateRange[1] == null) return;
        LogitarApi.getEmissionsAverages("jobs", dateRange[0], dateRange[1]).then(res => {
            const typedRes = res as { result: EmissionLimitedJob[], status: boolean };
            setJobs(typedRes.result);
        }).catch(err => {
            setStatus(AvgStatus.FetchError);
            console.error(err);
        })
    }

    const fetchLastAverages = () => {
        if (dateRange[0] == null || dateRange[1] == null) return;
        setStatus(AvgStatus.LastFetch);
        LogitarApi.getEmissionsAverages("averages", dateRange[0], dateRange[1]).then(res => {
            const typedRes = res as { result: EmissionsAverage[], status: boolean };
            setLastData(typedRes.result);
            setStatus(AvgStatus.Idle);
        }).catch(err => {
            setStatus(AvgStatus.FetchError);
            console.error(err);
        })
    }

    const saveAverages = () => {
        setStatus(AvgStatus.Saving);
        const saveData = newData.filter(d => isAverageInvalid({ km: d.avgTotalKm, fuel: d.avgTotalFuel }) === false);
        LogitarApi.setEmissionsAverages(saveData).then(res => {
            const typedRes = res as { status: boolean };
            if (typedRes.status === true) {
                setStatus(AvgStatus.Done);
            }
        }).catch(err => {
            if (err?.reason === "emptyAverages") {
                enqueueSnackbar("Ei tallennettavia rivejä", { variant: "warning" });
            }
            setStatus(AvgStatus.SaveError);
            console.error(err);
        })
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "auto", p: 1, pt: 2, gap: 1 }}>
            <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
                <DateRangeSelect
                    value={dateRange}
                    onChange={(v) => {
                        setDateRange(v);
                    }}
                    labels={{
                        start: "Ajopvm alku",
                        end: "Ajopvm loppu"
                    }}
                    inputStyle={{ paddingTop: "10px", paddingBottom: "10px" }}
                    disableFuture
                />
                <Tooltip title="Vaihda aikaväli">
                    <span>
                        <Button
                            variant="contained"
                            disabled={dateRange[0] === lastDateRange[0] && dateRange[1] === lastDateRange[1]}
                            onClick={() => {
                                fetchItems();
                            }}
                            sx={{ width: "100%", height: "100%" }}
                        ><EventRepeat /></Button>
                    </span>
                </Tooltip>
                <Button
                    startIcon={status === AvgStatus.JobFetch ? <CircularProgress size="20px" /> : <Calculate />}
                    variant="contained"
                    disabled={status === AvgStatus.JobFetch}
                    onClick={() => fetchJobs()}
                >Tee laskenta</Button>
                <Button
                    startIcon={status === AvgStatus.Saving ? <CircularProgress size="20px" /> : <Save />}
                    variant="contained"
                    disabled={newData.length < 1 || status === AvgStatus.Saving || status === AvgStatus.Done}
                    onClick={() => saveAverages()}
                >Tallenna keskiarvot</Button>
                <Button
                    startIcon={<ListAlt />}
                    variant="outlined"
                    onClick={() => setShowManager(true)}
                >Laskennat</Button>
                <ColorInfo colors={[
                    { class: "row-default-red", info: "Virheellinen rivi, jota ei tallenneta keskiarvoksi" }
                ]} />
            </Stack>
            <Box sx={{ minHeight: "2rem", display: "flex", alignItems: "center" }}>
                <Box sx={{ px: 1 }}>
                    {renderStatus(status)}
                </Box>
            </Box>
            <Box sx={{ height: "calc(100% - 91px)" }}>
                <DataGridPro
                    columns={columns}
                    rows={tableRows}
                    density="compact"
                    getRowClassName={(params) => {
                        if (newData.length > 0 &&
                            isAverageInvalid({ fuel: params.row?.newTotalFuel, km: params.row?.newTotalKm }) !== false) {
                            return "row-default-red";
                        }
                        return "";
                    }}
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={[
                        {
                            groupId: "last",
                            headerName: "Edeltävä",
                            children: [
                                { field: "lastDates" },
                                { field: "lastJobCount" },
                                { field: "lastTotalKm" },
                                { field: "lastTotalFuel" },
                                { field: "lastTotalEmissions" }
                            ]
                        },
                        {
                            groupId: "new",
                            headerName: "Uusi laskenta",
                            children: [
                                { field: "newDates" },
                                { field: "newJobCount" },
                                { field: "newTotalKm" },
                                { field: "newTotalFuel" },
                                { field: "newTotalEmissions" }
                            ]
                        }
                    ]}
                    columnVisibilityModel={{
                        id: false
                    }}
                />
            </Box>
            <EmissionAveragesManager open={showManager} onClose={() => setShowManager(false)} />
        </Box>
    )
}

function buildAveragesTableRows(items: EmissionAveragesItem[], lastData: EmissionsAverage[], newData: Partial<EmissionsAverage>[], multipliers: Multiplier[]): EmissionAvgTableRow[] {
    return items.map(i => {
        const lastAvg = lastData.find(l => l.item === i.id && l.fuelType === i.fuelType);
        const newAvg = newData.find(n => n.item === i.id && n.fuelType === i.fuelType);
        const emissionsMultiplier = Number(multipliers.find(fte => fte.type === i.fuelType)?.perUnit) || 0;
        return {
            id: `${i.id}-${i.fuelType}`,
            itemId: i.id,
            name: i.name,
            fuelType: i.fuelType,
            jobCount: i.jobCount,
            lastDates: lastAvg ? [lastAvg.dateStart, lastAvg.dateEnd] : undefined,
            lastTotalKm: lastAvg?.avgTotalKm,
            lastTotalFuel: lastAvg?.avgTotalFuel,
            lastTotalEmissions: lastAvg?.avgTotalFuel ? lastAvg.avgTotalFuel * emissionsMultiplier : undefined,
            lastJobCount: lastAvg?.jobCount,
            newDates: newAvg?.dateStart && newAvg?.dateEnd ? [newAvg.dateStart, newAvg.dateEnd] : undefined,
            newTotalKm: newAvg?.avgTotalKm,
            newTotalFuel: newAvg?.avgTotalFuel,
            newTotalEmissions: newAvg?.avgTotalFuel ? newAvg.avgTotalFuel * emissionsMultiplier : undefined,
            newJobCount: newAvg?.jobCount,
            diffKm: lastAvg?.avgTotalKm && newAvg?.avgTotalKm ? newAvg.avgTotalKm - lastAvg.avgTotalKm : undefined,
            diffFuel: lastAvg?.avgTotalFuel && newAvg?.avgTotalFuel ? newAvg.avgTotalFuel - lastAvg.avgTotalFuel : undefined,
            diffEmissions: lastAvg?.avgTotalFuel && newAvg?.avgTotalFuel ?
                (newAvg.avgTotalFuel * emissionsMultiplier) - (lastAvg.avgTotalFuel * emissionsMultiplier) : undefined,
            diffJobCount: lastAvg && newAvg?.jobCount ? newAvg.jobCount - lastAvg.jobCount : undefined,
        }
    })
}

function calculateItemAverages(items: EmissionAveragesItem[], jobs: EmissionLimitedJob[], lastDates: DateRange<Date>): Partial<EmissionsAverage>[] {
    return items.map(i => {
        const itemJobs = jobs.filter(j => j.item === i.id && j.fuelType === i.fuelType);
        const kmTotalCount = itemJobs.filter(j => j.distanceTotal != null && j.distanceTotal > 0).length;
        const kmJobCount = itemJobs.filter(j => j.distanceJob != null && j.distanceJob > 0).length;
        const fuelTotalCount = itemJobs.filter(j => j.fuelTotal != null && j.fuelTotal > 0).length;
        const fuelJobCount = itemJobs.filter(j => j.fuelJob != null && j.fuelJob > 0).length;
        return {
            item: i.id,
            fuelType: i.fuelType,
            jobCount: itemJobs.length,
            dateStart: lastDates[0] != null ? format(lastDates[0], "yyyy-MM-dd") : undefined,
            dateEnd: lastDates[1] != null ? format(lastDates[1], "yyyy-MM-dd") : undefined,
            avgTotalKm: kmTotalCount > 0 ? Math.round(itemJobs.reduce((p, c) => p += c.distanceTotal || 0, 0) / kmTotalCount) : undefined,
            avgJobKm: kmJobCount > 0 ? Math.round(itemJobs.reduce((p, c) => p += c.distanceJob || 0, 0) / kmJobCount) : undefined,
            avgTotalFuel: fuelTotalCount > 0 ? Math.round(itemJobs.reduce((p, c) => p += c.fuelTotal || 0, 0) / fuelTotalCount) : undefined,
            avgJobFuel: fuelJobCount > 0 ? Math.round(itemJobs.reduce((p, c) => p += c.fuelJob || 0, 0) / fuelJobCount) : undefined,
        };
    })
}

function isAverageInvalid(row: { km?: number | null, fuel?: number | null }) {
    const reasons: InvalidAvgReason[] = [];
    if (row.km == null) reasons.push("fuelNull");
    if (row.km && row.km < 0) reasons.push("fuelNegative");
    if (row.fuel == null) reasons.push("kmNull");
    if (row.fuel && row.fuel < 0) reasons.push("kmNegative");
    return reasons.length > 0 ? reasons : false;
}

function dateRangeFormatter(dateRange: DateRange<Date>) {
    if (dateRange != null && dateRange[0] != null && dateRange[1] != null) {
        return `${format(new Date(dateRange[0]), "MM.dd.")}-${format(new Date(dateRange[1]), "MM.dd.")}`;
    }
    return null;
}