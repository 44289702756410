import LogitarApi from "../api/LogitarApi";

type CalcPeriodHours = {
    id: number, 
    workHours: number, 
    eveningHours: number, 
    nightHours: number,
}

export async function getUserPeriodsHours(userId: number, timeframe: {from: Date, to: Date} ): Promise<CalcPeriodHours> {
    try {
        const userWH = await LogitarApi.getWorkHours(timeframe.from, timeframe.to, userId)
        const totals = userWH.workhours[0].workhours.reduce((acc: CalcPeriodHours, wh) => {
            acc.workHours += wh.workHours
            acc.eveningHours += wh.eveningHours
            acc.nightHours += wh.nightHours
            return acc
        }, { id: userId, workHours: 0, eveningHours: 0, nightHours: 0 })
        return totals
    } catch (error) {
        return { id: userId, workHours: 0, eveningHours: 0, nightHours: 0 }
    }
}