import LogiTarUser from "../misc/User";

const LogitarEventPorts = {
    "softrain": {
        er: 15800,
        ep: 15900
    },
    "tarkkala": {
        er: 15801,
        ep: 15901
    },
    "konnekuljetus": {
        er: 15802,
        ep: 15902
    },
    "metsanen": {
        er: 15803,
        ep: 15903
    }
}

const LogitarEventPortsDev = {
    "softrain": {
        er: 16800,
        ep: 16900
    },
    "tarkkala": {
        er: 16801,
        ep: 16901
    },
    "konnekuljetus": {
        er: 16802,
        ep: 16902
    },
    "metsanen": {
        er: 16803,
        ep: 16903
    }
}

/**
 * @enum {string} LogitarEventStatus
 */
const LogitarEventStatus = {
    "connecting": "connecting",
    "connected": "connected",
    "disconnected": "disconnected"
}

const PING_INTERVAL = 10000;
const PONG_TIMEOUT = 3000;
const RECONNECT_TIMEOUT = 5000;


/**
 * @typedef {string} LogitarEventSource
 */

/**
 * @callback EventSubscriptionCallback
 * @param {LogitarEventSource} source
 * @param {any} data
 * @param {number} id
 * @returns {void}
 */

/**
 * @callback ConnectionEventCallback
 * @param {number} reconnectCount
 * @returns {void}
 */

/**
 * @callback StatusChangeCallback
 * @param {LogitarEventStatus} status
 * @returns {void}
 */

let __event_id = 1;

let __event_counter = 1;

export const getEventCounter = () => (__event_counter++);

let __reconnect_counter = 0;

export default class LogitarEvent {

    /**
     * @type {WebSocket | null}
     */
    static ws = null;

    /**
     * @type {string | null}
     */
    static url = null;

    /**
     * @type {{id: number, source: LogitarEventSource, callback: EventSubscriptionCallback}[]}
     */
    static subscriptions = [];

    static isConnected = false;
    static initialized = false;

    /**
     * @type {LogitarEventStatus}
     */
    static status = LogitarEventStatus.disconnected;

    /**
     * @private
     * @type {{id: number, source: LogitarEventSource, callback: EventSubscriptionCallback}[]}
     */
    static pendingSubs = [];

    /**
     * @type {number | null}
     */
    static pingTimeout = null;

    /**
     * @type {number | null}
     */
    static pongTimeout = null;

    /**
     * @type {{id: number, callback: ConnectionEventCallback}[]}
     */
    static connectionEventListeners = [];

    /**
     * @type {{id: number, callback: ConnectionEventCallback}[]}
     */
    static disconnectionEventListeners = [];

    /**
     * @type {{id: number, callback: StatusChangeCallback}[]}
     */
    static statusChangeListeners = [];

    static connect (url) {

        if(LogitarEvent.isConnected || LogitarEvent.status === LogitarEventStatus.connecting)
            return;

        LogitarEvent.setStatus(LogitarEventStatus.connecting);

        if(!LogitarEvent.initialized) {
            LogitarEvent.url = url;
            document.addEventListener("visibilitychange", () => {
                    if(document.visibilityState === "visible") {
                        LogitarEvent.ping();
                    }
            });
            LogitarEvent.initialized = true;
        }

        LogitarEvent.ws = new WebSocket(url);

        LogitarEvent.ws.onerror = (ev) => {
            console.error("LogitarEvent error", ev);
        }

        LogitarEvent.ws.onopen = LogitarEvent.onConnect;
        LogitarEvent.ws.onmessage = LogitarEvent.onMessage;
        LogitarEvent.ws.onclose = LogitarEvent.onDisconnect;

    }

    /**
     * @brief Subscribe to events
     * @param {LogitarEventSource[]} sources 
     * @param {EventSubscriptionCallback} callback
     * @param {boolean} reset
     */
    static subscribe (sources, callback, reset = false, forcedId = undefined) {

        const id = forcedId !== undefined ? forcedId : __event_id++;

        if(!LogitarEvent.isConnected) {
            for(let source of sources) {
                // Add pending listeners
                LogitarEvent.pendingSubs.push({id: id, source: source, callback: callback})
            }
            return id;
        }

        const msg = {
            cmd: "subscribe",
            reset: reset, // Reset all subscriptions
            id: id,
            sources: sources
        }
        LogitarEvent.ws.send(JSON.stringify(msg));
        // Add listeners
        for(let source of sources) {
            LogitarEvent.subscriptions.push({
                id: id,
                source: source,
                callback: callback
            });
        }
        console.log("Subscribed to events");

        return id;
    }

    /**
     * @brief Unsubscribe from events
     * @param {number} id 
     */
    static unsubscribe (id) {
        if(!LogitarEvent.isConnected)
            return;

        const msg = {
            cmd: "unsubscribe",
            id: id
        }
        LogitarEvent.ws.send(JSON.stringify(msg));

        // Remove listeners
        LogitarEvent.subscriptions = LogitarEvent.subscriptions.filter(e => e.id !== id);

        console.log("Unsubscribed from events");
    }

    // Connection/disconnection event listeners

    /**
     * @brief Add connection event listener
     * @param {ConnectionEventCallback} callback 
     * @returns {number} ID for callback
     */
    static addConnectionListener (callback) {
        const id = __event_id++;
        LogitarEvent.connectionEventListeners.push({id: id, callback: callback});
        return id;
    }

    /**
     * @brief Remove connection event listener
     * @param {number} id 
     */
    static removeConnectionListener (id) {
        const ix = LogitarEvent.connectionEventListeners.findIndex(e => e.id === id);
        if(ix >= 0) {
            LogitarEvent.connectionEventListeners.splice(ix, 1);
        }
    }


    /**
     * @brief Add disconnection event listener
     * @param {ConnectionEventCallback} callback 
     * @returns {number} ID for callback
     */
    static addDisconnectionListener (callback) {
        const id = __event_id++;
        LogitarEvent.disconnectionEventListeners.push({id: id, callback: callback});
        return id;
    }

    /**
     * @brief Remove disconnection event listener
     * @param {number} id 
     */
    static removeDisconnectionListener (id) {
        const ix = LogitarEvent.disconnectionEventListeners.findIndex(e => e.id === id);
        if(ix >= 0) {
            LogitarEvent.disconnectionEventListeners.splice(ix, 1);
        }
    }

    /**
     * @brief Add status change listener
     * @param {StatusChangeCallback} callback
     * @returns {number} ID for callback
     */
    static addStatusChangeListener (callback) {
        const id = __event_id++;
        LogitarEvent.statusChangeListeners.push({id: id, callback: callback});
        return id;
    }

    /**
     * @brief Remove status change listener
     * @param {number} id
     * @returns {void}
     */
    static removeStatusChangeListener (id) {
        const ix = LogitarEvent.statusChangeListeners.findIndex(e => e.id === id);
        if(ix >= 0) {
            LogitarEvent.statusChangeListeners.splice(ix, 1);
        }
    }
    
    /**
     * @brief Sets status and calls all listeners
     * @param {LogitarEventStatus} status 
     */
    static setStatus (status) {
        LogitarEvent.status = status;
        for(let listener of LogitarEvent.statusChangeListeners) {
            listener.callback(LogitarEvent.status);
        }
    }

    static ping () {
        if(!LogitarEvent.isConnected) {
            console.log("(PING) Not connected to event system");
            return;
        }

        if(LogitarEvent.pingTimeout !== null) {
            clearInterval(LogitarEvent.pingTimeout);
            LogitarEvent.pingTimeout = null;
        }

        // Set ping interval
        LogitarEvent.pingTimeout = setTimeout(() => LogitarEvent.ping(), PING_INTERVAL);

        try {
            LogitarEvent.ws.send(JSON.stringify({
                cmd: "ping"
            }));
        }
        catch(e) {
            console.log("Sending ping failed");
            LogitarEvent.onDisconnect();
            return;
        }

        if(LogitarEvent.pongTimeout === null) {
            LogitarEvent.pongTimeout = setTimeout(() => {
                console.log("Pong timeout");
                LogitarEvent.ws.close();
                // Have to call onDisconnect manually
                LogitarEvent.onDisconnect();
            }, PONG_TIMEOUT);
        }
    }

    static onConnect () {
        LogitarEvent.isConnected = true;
        LogitarEvent.ws.send(JSON.stringify({
            cmd: "reg",
            user: {
                id: LogiTarUser.current.info.id,
                userType: LogiTarUser.current.info.userType
            }
        }))

        setTimeout(() => {
            for(let pend of LogitarEvent.pendingSubs) {
                // Subscribe to pending
                LogitarEvent.subscribe([pend.source], pend.callback, false, pend.id);
            }
            LogitarEvent.pendingSubs = [];
            // Set connected status
            LogitarEvent.setStatus(LogitarEventStatus.connected);
            for(let connectionEvent of LogitarEvent.connectionEventListeners) {
                connectionEvent.callback(__reconnect_counter);
            }
        }, 100);

        if(LogitarEvent.pingTimeout !== null) {
            clearInterval(LogitarEvent.pingTimeout);
            LogitarEvent.pingTimeout = null;
        }

        LogitarEvent.pingTimeout = setTimeout(() => {
            LogitarEvent.ping();
        }, PING_INTERVAL);

        console.log("Connected to LogitarEvent");
    }

    static onDisconnect () {
        __reconnect_counter++;
        LogitarEvent.setStatus(LogitarEventStatus.disconnected);

        LogitarEvent.isConnected = false;
        clearTimeout(LogitarEvent.pingTimeout);
        LogitarEvent.pingTimeout = null;
        if(LogitarEvent.pongTimeout !== null) {
            clearTimeout(LogitarEvent.pongTimeout);
            LogitarEvent.pongTimeout = null;
        }
        // Attempt to reconnect
        setTimeout(() => {
            LogitarEvent.connect(LogitarEvent.url);
        }, RECONNECT_TIMEOUT);

        console.log("Disconnected from LogitarEvent");

        // Add event listeners to pending
        for(let sub of LogitarEvent.subscriptions) {
            LogitarEvent.pendingSubs.push(sub);
        }
        // Clear subscriptions
        LogitarEvent.subscriptions = [];

        for(let disconnectEvent of LogitarEvent.disconnectionEventListeners) {
            disconnectEvent.callback(__reconnect_counter);
        }
    }

    /**
     * 
     * @param {MessageEvent<string>} ev 
     */
    static onMessage (ev) {

        const json = JSON.parse(ev.data);

        switch(json.cmd) {
            case "event":

                for(let sub of LogitarEvent.subscriptions) {
                    if(json.source === sub.source) {
                        sub.callback(json.source, json.data, getEventCounter());
                    }
                }

                break;
            case "pong":
                if(LogitarEvent.pongTimeout !== null) {
                    clearTimeout(LogitarEvent.pongTimeout);
                    LogitarEvent.pongTimeout = null;
                }
                break;
        }
    }

}