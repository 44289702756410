import { SxProps, Theme } from "@mui/material";
import { DatePicker, DateValidationError, LocalizationProvider, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { DateRange, DateRangePicker, DateRangeValidationError } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDefaultOptions } from "date-fns";
import { fi } from "date-fns/locale";
import { CSSProperties } from "react";

setDefaultOptions({ locale: fi });

export interface DateSelectCommonProps {
    sx?: SxProps<Theme>,
    className?: string;
    disabled?: boolean;
    readOnly?: boolean;
}


export interface DateSelectProps extends DateSelectCommonProps {
    value: Date;
    onChange: (date: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => void;
}

export default function DateSelect(props: DateSelectProps) {

    return <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
        <DatePicker
            {...props}
        />
    </LocalizationProvider>
}

export interface DateRangeSelectProps extends DateSelectCommonProps {
    value: DateRange<Date>;
    onChange: (dateRange: DateRange<Date>, context: PickerChangeHandlerContext<DateRangeValidationError>) => void;
    labels?: {start: string, end: string};
    isLoading?: {isLoading: boolean};
    disableFuture?: boolean;
    inputStyle?: CSSProperties;
    minDate?: Date;
    maxDate?: Date;
    onError?: (error: DateRangeValidationError, value: DateRange<Date>) => void;
}

export function DateRangeSelect(props: DateRangeSelectProps) {

    return <LocalizationProvider adapterLocale={fi} dateAdapter={AdapterDateFns}>
        <DateRangePicker
            displayWeekNumber
            localeText={{
                start: props.labels ? props.labels.start : 'Alku',
                end: props.labels ? props.labels.end : 'Loppu',
            }}
            {...props}
            onChange={(dateRange, context) => {
                if(dateRange[0] && dateRange[1] && dateRange[0] > dateRange[1]) {
                    dateRange = [dateRange[1], dateRange[0]]
                }

                // If one date is invalid (start > end || end < start) use same date for both
                if (!dateRange[0] && !!dateRange[1]) dateRange[0] = dateRange[1];
                if (!dateRange[1] && !!dateRange[0]) dateRange[1] = dateRange[0];
                if (!dateRange[0] && !dateRange[1]) return;

                // Earlier than 1900-01-01 date was red. Default minimum date can be set using DateRangePicker minDate attribute
                const cutoffDate = new Date('1900-01-01');
                if (!dateRange[0] || isNaN(dateRange[0].getTime()) || (dateRange[0].getTime() < cutoffDate.getTime()) ) { return }
                if (!dateRange[1] || isNaN(dateRange[1].getTime()) || (dateRange[1].getTime() < cutoffDate.getTime()) ) { return }

                props.onChange(dateRange, context);
            }}
            disabled={props.isLoading ? true : false}
            disableFuture={props.disableFuture}
            slotProps={{
                textField: {
                    inputProps: {
                        style: props.inputStyle,
                    }
                }
            }}
            minDate={props.minDate}
            maxDate={props.maxDate}
            onError={(error, value) => props.onError && props.onError(error, value)}
        />
    </LocalizationProvider>
}