import { Autocomplete, Box, CircularProgress, FormControl, FormGroup, IconButton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import MapSelector, { checkLatLng, parseLatLngString } from "./MapSelector";
import PopupScreen from "./PopupScreen";
import ItemEdit from "./ItemEdit";
import AlertPop from "./AlertPop";
import FileManager from "./FileManager.tsx";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { PinDrop } from "@mui/icons-material";

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  coordinates: string,
 *  radius: number,
 *  address: string,
 *  type: number,
 *  details: string,
 *  clients: number[]
 * }} AreaInfo
 */


/**
 * @type {AreaInfo}
 */

/**
 * 
 * @param {string} address 
 * @returns {Promise<{lat: number, lng: number}>}
 */
export const geolocateAddress = (address) => {
    return new Promise((resolve, reject) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === "OK") {
                resolve({
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                })
            }
            else {
                reject(status);
            }
        })
    })
}

/**
 * @param {{lat: number, lng: number}} coordinates 
 * @returns {Promise<{address: string}>}
 */
export const geolocateCoordinates = (coordinates) => {
    return new Promise((resolve, reject) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: coordinates }, (results, status) => {
            if (status === "OK") {
                const address = results.find((e) => e.types.includes("street_address")) || results[0];
                if (!address) {
                    reject(status);
                }
                resolve({
                    address: address.formatted_address
                })
            }
            else {
                reject(status);
            }
        })
    })
}

/**
 * @typedef {{
 *  onClose: (newArea: number | undefined) => void,
 *  info: AreaInfo | undefined
 * }} AreaEditProps
 */

/**
 * 
 * @param {AreaEditProps} props 
 * @returns 
 */
export default function AreaEdit(props) {

    const defaultArea = {
        name: "",
        coordinates: null,
        radius: null,
        type: props.areatype,
        details: "",
        clients: []
    }

    const [areaInfo, setAreaInfo] = useState(props.info ? props.info : defaultArea);
    const [clients, setClients] = useState([]);
    const [items, setItems] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const [showDeletePopup, setShowDeletePopup] = useState(false);

    const [count, setCount] = useState(0);

    const detailOptions = useRef([])

    const theme = useTheme();
    const mdMediaQuery = useMediaQuery(theme.breakpoints.up("md"));
    const [saveMode, setSaveMode] = useState(null);

    useEffect(() => {
        if (areaInfo.id) {
            LogitarApi.getAreas({ extent: 'all', id: areaInfo.id })
                .then((resp) => {
                    setAreaInfo(resp.areas[0]);
                })
                .catch((err) => {

                })
        }
        LogitarApi.getClients({ extent: 'list' })
            .then((resp) => {
                if (resp.status) {
                    setClients(resp.clients.map(e => ({ id: e.id, name: e.name })));
                }
            })
            .catch((err) => {

            })

        LogitarApi.getItems({ extent: 'list' }).then((resp) => {
            if (resp.status) {
                setItems(resp.items.map(e => ({ id: e.id, name: e.name })));
                const _details = new Set();

                resp.items.forEach((item) => {
                    if (item.detailDesc)
                        _details.add(String(item.detailDesc).trim())
                })

                detailOptions.current = Array.from(_details)

            }
        })
            .catch((err) => {

            })
    }, []);

    const onChange = e => {
        e.stopPropagation();
        setAreaInfo(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleItemPopupOpen = (id) => {
        LogitarApi.getItems({ id: id, extent: "allfile" }).then((result) => {
            if (result.status) {
                let item = result.items[0]
                setEditItem(item)
                console.log(item)
                console.log("Fetched: " + item.id)
            }
        })
            .catch((err) => console.error(err))
    }

    function saveArea() {


        setSaveMode(true);
        if (checkSaveIsValid() == false) {
            enqueueSnackbar("Täytä kaikki pakolliset kentät", { variant: "error" })
            return;
        }

        const ainfo = { ...areaInfo };
        setSaveLoading(true);
        if (ainfo.files) {
            ainfo.files.forEach((e) => {
                if (!String(e.id).includes("NEW-")) {
                    // Delete data if it's not a new file
                    delete e.data;
                }
            })
        }

        if (ainfo.coordinates === null) {
            ainfo.coordinates = "";
        }

        LogitarApi.setArea(ainfo).then((result) => {
            if (result.status) {
                enqueueSnackbar("Alue tallennettu");
                props.onClose(result.latestID || undefined);
            }
            else {
                enqueueSnackbar("Alueen tallennus epäonnistui", { variant: "error" });
            }
        })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar("Alueen tallennus epäonnistui", { variant: "error" });
            })
            .finally(() => {
                setSaveLoading(false);
            })
    }

    function deleteArea() {
        LogitarApi.setArea({ ...areaInfo, del: true })
            .then((res) => {
                enqueueSnackbar("Alue poistettu");
                setShowDeletePopup(false);
                props.onClose();
            })
            .catch((err) => {
                console.error(err)
                enqueueSnackbar("Alueen poisto epäonnistui", { variant: "error" });
            })
    }

    const centerCoords = (areaInfo.coordinates && checkLatLng(areaInfo.coordinates)) ?
        parseLatLngString(areaInfo.coordinates) :
        undefined;

    let deleteTexts = [];
    if (areaInfo.clients && areaInfo.clients.length > 0) {
        deleteTexts.push("Alueeseen on yhdistetty asiakkaita");
    }
    if (areaInfo.items && areaInfo.items.length > 0) {
        deleteTexts.push("Alueeseen on yhdistetty nimikkeitä");
    }
    let deleteTooltip = null;
    if (deleteTexts.length > 0) {
        deleteTooltip = <>
            <span>
                Aluetta ei voi poistaa:
            </span>
            <ul>
                {deleteTexts.map((v, i) => <li key={i}>{v}</li>)}
            </ul>
        </>;
    }

    const checkIsEmpty = (field, value) => {
        if (!areaInfo.id && saveMode == null) return false;
        if (!value) {
            return true;
        }
        if (value && value.length < 1) {
            return true;
        }
        return false;
    };

    const checkSaveIsValid = () => {
        if (areaInfo.name == null || areaInfo.name.length < 1) return false;
        if (areaInfo.address == null || areaInfo.address.length < 1) return false;
        //if (areaInfo.coordinates == null || areaInfo.coordinates.length < 1 ) return false;
        if (areaInfo.radius == null || areaInfo.radius.length < 1) return false;
        if (areaInfo.details == null || areaInfo.details.length < 1) return false;
        if ((clients.filter((v) => areaInfo.clients.includes(v.id)).length) < 1) return false;
        return true;
    }

    return <PopupScreen
        draggable
        title={areaInfo.id ? "Muokkaa aluetta" : "Uusi alue"}
        staticButtons
        onClose={() => { props.onClose() }}
        onSave={() => {
            saveArea();
        }}
        deleteProps={{
            disabled: ((areaInfo.clients && areaInfo.clients.length > 0) ||
                (areaInfo.items && areaInfo.items.length > 0)) ||
                (!areaInfo.clients || !clients)
        }}
        onDelete={areaInfo.id ? () => setShowDeletePopup(true) : undefined}
        deleteTooltip={deleteTooltip}
        saveLoading={saveLoading}
    >
        <FormControl
            sx={{
                '& .MuiTextField-root': { mr: 1, mb: 1.5, flex: 1 },
                '& .MuiFormGroup-root': { display: "flex" },
            }}
        >
            <FormGroup sx={{ flexDirection: mdMediaQuery ? "row" : "column", gap: 1, mr: 1 }}>
                <FormGroup sx={{ flexDirection: "column", minWidth: '30vw', flex: 1, gap: "12px 8px" }}>
                    <FormGroup row sx={{ alignItems: "center" }}>
                        <Typography variant="caption">Lastaus</Typography>
                        <Switch
                            value={areaInfo.type}
                            checked={areaInfo.type === 1}
                            name="type"
                            onChange={(e, c) => { setAreaInfo({ ...areaInfo, type: c ? 1 : 0 }) }}
                        />
                        <Typography variant="caption">Purku</Typography>
                    </FormGroup>
                    <FormGroup row>
                        <TextField value={areaInfo.name} label="Nimi" name="name" onChange={onChange} required aria-required error={checkIsEmpty("name", areaInfo.name)} style={{ margin: 0 }} />
                    </FormGroup>
                    <FormGroup row>
                        <TextField value={areaInfo.address} label="Osoite" name="address" onChange={onChange} required aria-required error={checkIsEmpty("address", areaInfo.address)} style={{ margin: 0 }} />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%"
                            }}
                        >
                            <Tooltip title="Hae osoitteen koordinaatit">
                                <IconButton
                                    onClick={() => {
                                        geolocateAddress(areaInfo.address)
                                            .then((coords) => {
                                                setAreaInfo({
                                                    ...areaInfo,
                                                    coordinates: `${coords.lat.toFixed(5)},${coords.lng.toFixed(5)}`
                                                })
                                            })
                                            .catch((err) => {
                                                enqueueSnackbar("Osoitteen haku epäonnistui", { variant: "error" })
                                            })
                                    }}
                                >
                                    <PinDrop />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </FormGroup>
                    <FormGroup row sx={{ flexWrap: "wrap", gap: "12px 8px" }}>
                        <TextField label="Koordinaatit" name="coordinates" value={areaInfo.coordinates} onChange={onChange} sx={{ minWidth: "8rem" }} style={{ margin: 0 }} />
                        <Tooltip title="Hae osoite koordinaateista">
                            <IconButton
                                sx={{ marginLeft: -1 }}
                                onClick={() => {
                                    geolocateCoordinates(centerCoords)
                                        .then((address) => {
                                            setAreaInfo({
                                                ...areaInfo,
                                                address: address.address
                                            })
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar("Koordinaattien haku epäonnistui", { variant: "error" })
                                        })
                                }}
                            >
                                <PinDrop sx={{transform: "rotate(180deg)"}} />
                            </IconButton>
                        </Tooltip>
                        <TextField 
                            type="number" 
                            name="radius" 
                            value={areaInfo.radius} 
                            onChange={onChange} 
                            label="Alueen säde (m)" 
                            sx={{ minWidth: "8rem" }} 
                            required 
                            aria-required 
                            error={checkIsEmpty("radius", areaInfo.radius)} 
                            style={{ margin: 0 }} 
                            inputProps={{ min: 0, step: 50 }}
                        />
                    </FormGroup>
                    <TextField
                        label="Ohjeet"
                        multiline
                        inputProps={{
                            resize: "both"
                        }}
                        maxRows={8}
                        sx={{ width: "100%", flex: "0 !important" }}
                        name="details"
                        value={areaInfo.details}
                        onChange={onChange}
                        required aria-required
                        error={checkIsEmpty("details", areaInfo.details)}
                        style={{ margin: 0 }}
                    />

                    {
                        (areaInfo.clients && clients) &&
                        <Autocomplete
                            sx={{ maxWidth: "100%", maxHeight: 300 }}
                            multiple
                            options={clients}
                            getOptionLabel={(option) => option.name}
                            value={clients.filter((v) => areaInfo.clients.includes(v.id))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Asiakaskoodi"
                                    placeholder="Asiakaskoodi"
                                    required aria-required
                                    error={checkIsEmpty("clients", clients.filter((v) => areaInfo.clients.includes(v.id)))}
                                    style={{ margin: 0 }}
                                />
                            )}
                            onChange={(e, v) => {
                                setAreaInfo({
                                    ...areaInfo,
                                    clients: v.map(y => y.id)
                                })
                            }}
                        />
                    }
                    {
                        (!areaInfo.clients || !clients) &&
                        <CircularProgress />
                    }
                    {
                        (items?.length > 0 && areaInfo.items?.length > 0) &&
                        <Box sx={{ width: "100%", height: 140, position: "relative" }}>
                            <DataGridPro
                                columns={[
                                    { field: "id", headerName: "ID", width: 30 },
                                    { field: "name", headerName: "Nimike", flex: 1 },
                                ]}
                                columnHeaderHeight={40}
                                hideFooter
                                loading={(!areaInfo.items || !items)}
                                disableRowSelectionOnClick
                                rowHeight={32}
                                rows={items.filter((v) => areaInfo.items.includes(v.id))}
                                size="small"
                                sx={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    mb: 1.5,
                                }}
                                onRowClick={(e) => handleItemPopupOpen(e.row.id)}
                            />
                        </Box>
                    }
                </FormGroup>
                <FormGroup sx={{ flex: 1 }}>
                    <MapSelector
                        style={{
                            flex: 1,
                            maxWidth: "100%",
                            width: "unset",
                            minWidth: mdMediaQuery ? "30vw" : "40vw",
                            minHeight: "14rem"
                        }}
                        point={centerCoords}
                        radius={areaInfo.radius ? Number(areaInfo.radius) : undefined}
                        initialCenter={centerCoords}
                        initialZoom={centerCoords ? 11 : undefined}
                        onChange={(p) => {
                            if (p.lat && p.lng && p.radius) {
                                setAreaInfo({
                                    ...areaInfo,
                                    coordinates: `${p.lat.toFixed(5)},${p.lng.toFixed(5)}`,
                                    radius: p.radius.toFixed(0)
                                });

                            }
                        }}
                        allowCircleResize
                    />
                    <Box sx={{ width: "100%", height: "9rem", position: "relative", my: 1 }}>
                        <FileManager
                            files={areaInfo.files}
                            sx={{ position: "absolute", height: "9rem", left: 0, right: 0 }}
                            onChange={(files) => {
                                setAreaInfo({
                                    ...areaInfo,
                                    files: files
                                })
                            }}
                        />
                    </Box>
                </FormGroup>
            </FormGroup>

        </FormControl>
        {
            editItem &&
            <ItemEdit
                editItem={editItem} detailOptions={detailOptions.current} popupOpen={(b) => setEditItem(b ? editItem : null)} newItem={false} rowUpdate={() => { setCount(count + 1) }}
            />
        }
        {
            showDeletePopup &&
            <AlertPop
                title="Haluatko varmasti poistaa alueen"
                cancellable
                onClose={(accepted) => {
                    if (accepted)
                        deleteArea();
                    else
                        setShowDeletePopup(false);
                }}
            />
        }
    </PopupScreen>
}