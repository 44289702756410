import { Box, Card, Tooltip, Typography } from "@mui/material"

/**
 * 
 * @param {{
 *  id: string, 
 *  text: string, 
 *  subtext?: string, 
 *  number?: string | JSX.Element, 
 *  dragStart: function, 
 *  onClick?: (e: Event) => void
 *  size?: string|number, 
 *  backgroundColor?: string, 
 *  color?: string, 
 *  className?: string,
 *  tooltip?: string,
 *  disableDrag?: boolean,
 *  dragStart: (e: DragEvent) => void,
 *  passive?: boolean,
 *  dragEnd: () => void
 * }} props 
 * @returns 
 */
export default function DragBox(props) {
    let size = props.size || '100%'

    return <Tooltip
        title={props.tooltip}
    >
        <Card
            data-testid="drag-box"
            id={props.id}
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: props.disableDrag ? "pointer" : "grab",
                margin: '5px',
                maxHeight: '50px',
                textAlign: 'center',
                padding: 0,
                width: size,
                backgroundColor: props.backgroundColor || "inherit",
                color: props.color || "inherit",
                opacity: props.passive ? 0.5 : undefined,
                textDecoration: props.passive ? "line-through" : undefined,
            }}
            className={props.className || ""}
            onDragStart={(e) => props.dragStart(e)}
            onDragEnd={props.dragEnd}
            draggable={!props.disableDrag}
            onClick={(e) => {
                if(props.onClick)
                    props.onClick(e);
            }}
        >
            {
                props.number &&
                <Box sx={{ flex: 1, maxWidth: 40, display: "flex", alignItems: "center" }}>
                    <Typography variant='h5' sx={{ flex: 1, borderRight: '1px solid rgba(0,0,0,0.2)' }}>{props.number}</Typography>
                </Box>
            }
            <Box sx={{ display: "flex", flexDirection: "column", flex: 3, "& .MuiTypography-root": { fontSize: '12px', lineHeight: '14px' } }}>
                <Typography variant='subtitle1'>
                    {props.text}
                </Typography>
                <Tooltip title={props.subtext} sx={{}}>
                    <Typography variant='caption' sx={{ color: '#777', overflow: 'hidden' }}>
                        {props.subtext}
                    </Typography>
                </Tooltip>
            </Box>
        </Card>
    </Tooltip>
}
