import { Add, Assignment, AssignmentTurnedIn, Cancel, Edit, GridView, Info, InfoOutlined, ViewListOutlined } from "@mui/icons-material";
import { Autocomplete, Button, CircularProgress, Divider, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPro, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import LogitarApi from "../api/LogitarApi";
import { ServiceMonitorType } from "../api/LogitarApiTypes";
import AlertPop from "../components/AlertPop";
import InspectionPop from "../components/InspectionPop";
import ServiceMonitorApprovePop from "../components/ServiceMonitorApprovePop";
import { ServiceMonitorCard, averageKmPerDay, calculateNextService } from "../components/ServiceMonitorCard";
import ServiceMonitorOrderPop from "../components/ServiceMonitorOrderPop";
import ServiceMonitorPop from "../components/ServiceMonitorPop";
import LogiTarUser, { LogiTarUserType } from "../misc/User";
import { useUserConfig } from "../misc/UserConfig";
import PersistentDataGridPro from "../components/persistent/PersistentDataGridPro";
import CustomToolbar from "../components/CustomToolbar";
import { localeText } from "../misc/LocaleText";
import ColorInfo from "../components/ColorInfo";


// Start of inspection ID's
const dgInspectionIdOffset = 10000000;

/**
 * @typedef {{
 *  id: number,
 *  serviceAt: string,
 *  vehicle: string,
 *  km: number,
 *  type: string,
 *  untilService: string,
 *  status: number,
 *  estDate: Date,
 *  vehicleId: number
 * }} ServiceMonitorDataGridType
 */

/**
 * @brief Transforms `monitors` object received from getServiceMonitor call to ServiceMonitorCard compatible format
 * @param {import("../api/LogitarApiTypes").GetServiceMonitorRow[]} rows 
 * @returns {ServiceMonitorVehicle[]} Monitors
 */
export const serviceMonitorResponseToCards = (rows) => {
    // Format for service cards
    /** @type {import("../components/ServiceMonitorCard").ServiceMonitorVehicle[]} */
    let mon = [];
    rows.forEach((e) => {
        let z = mon.find(y => y.vehicle.id === e.vehicle.id);
        let n = false;
        if (!z) {
            z = {
                vehicle: e.vehicle,
                monitors: []
            };
            n = true;
        }
        const m = { ...e };
        delete m.vehicle;
        z.monitors.push(m);
        // Push if new
        if (n)
            mon.push(z);
    })

    return mon;
}

export const daysUntilFormatter = (days) => {
    if (Math.abs(days) >= 365.25) {
        const daysPerMonth = 30.4375; // Average days in a month considering leap years
        let years = Math.floor(Math.abs(days) / 365.25);
        let months = Math.floor((Math.abs(days) - (365.25 * years)) / daysPerMonth)

        if (days < 0) {
            years = years * -1
        }

        if (Math.abs(months) > 0) {
            return ((years + "v") + " " + (months + "kk"))
        }
        else {
            return (years + "v")
        }
    }
    else if (Math.abs(days) >= 30) {
        const daysPerMonth = 30.4375;
        let months = Math.floor(Math.abs(days) / daysPerMonth) 
        if (days < 0) {
            months = months * -1
        }
        return (months + "kk");
    }
    else {
        return days.toFixed(0) + "pv";
    }
}


/**
 * @brief Transforms `monitors` object received from getServiceMonitor call to ServiceMonitorDataGridType
 * @param {import("../api/LogitarApiTypes").GetServiceMonitorRow[]} rows 
 * @returns {ServiceMonitorDataGridType[]} Monitors
 */
export const serviceMonitorResponseToDataGrid = (rows) => {

    const monitorLabels = {
        [ServiceMonitorType.OIL_CHANGE]: "Öljynvaihto",
        [ServiceMonitorType.INTERIM_SERVICE]: "Välihuolto",
        [ServiceMonitorType.YEARLY_SERVICE]: "3v huolto",
        0: "Katsastus"
    }

    // Day in ms
    const day2ms = 1000 * 3600 * 24;

    let last_oil_rounded = {};
    let next_oil_rounded = {};

    // Format for data grid
    // IMPORTANT: MUST BE SORTED BY TYPE SO OIL_CHANGE IS CALCULATED FIRST
    const mon = rows.filter(e => e.id !== null).sort((a, b) => a.type - b.type).map((e, i) => {
        const kilometres = Number(e.vehicle.kilometres || 0);

        let monitorInfo = {
            untilService: null,
            nextService: null,
            isKmService: null
        }

        switch (e.type) {
            case ServiceMonitorType.OIL_CHANGE:
                monitorInfo.untilService = calculateNextService(
                    kilometres,
                    Number(e.firstServiceKm),
                    Number(e.serviceIntervalKm),
                    e.previous ? Number(e.previous.serviceDoneKm) : null,
                    false
                );
                monitorInfo.nextService = kilometres + monitorInfo.untilService;
                monitorInfo.isKmService = true;
                next_oil_rounded[e.vehicle.id] = monitorInfo.nextService;
                last_oil_rounded[e.vehicle.id] = monitorInfo.nextService - Number(e.serviceIntervalKm);
                break;
            case ServiceMonitorType.INTERIM_SERVICE:
                monitorInfo.untilService = calculateNextService(
                    kilometres,
                    Number(e.firstServiceKm),
                    Number(e.serviceIntervalKm),
                    e.previous ? Number(e.previous.serviceDoneKm) : null,
                    false
                );
                monitorInfo.nextService = kilometres + monitorInfo.untilService;
                monitorInfo.isKmService = true;
                if (monitorInfo.nextService === next_oil_rounded[e.vehicle.id] || monitorInfo.nextService === last_oil_rounded[e.vehicle.id]) {
                    monitorInfo.nextService += Number(e.serviceIntervalKm);
                    monitorInfo.untilService += Number(e.serviceIntervalKm);
                }
                break;
            case ServiceMonitorType.YEARLY_SERVICE:
                const regDate = e.vehicle.registrationDate ? (new Date(e.vehicle.registrationDate).getTime()) : 0;
                const prevService = e.previous ? (new Date(e.previous.done).getTime()) : null;
                const prevRegDiff = prevService ? (prevService - regDate) : 0;
                // Get steps of 3 years from registration date which is closest to previous service date
                const prevRounded = e.previous ? 
                    (regDate + Math.round(prevRegDiff / (day2ms * 365.25 * 3)) * (day2ms * 365.25 * 3)) : null;
                monitorInfo.untilService = calculateNextService(
                    Date.now(),
                    regDate + (day2ms * 365.25 * 3),
                    day2ms * 365.25 * 3 /* 3 years */,
                    prevRounded,
                    true // yearly
                );

                monitorInfo.nextService = Date.now() + monitorInfo.untilService;
                monitorInfo.isKmService = false;
                break;
        }

        if (monitorInfo.untilService === null) {
            // Could not get good values
            monitorInfo.isKmService = false;
            monitorInfo.nextService = 0;
            monitorInfo.untilService = 0;
        }

        return {
            id: e.id,
            serviceAt: monitorInfo.isKmService ? (monitorInfo.nextService + "km") : format(new Date(monitorInfo.nextService), "dd.MM.yyyy"),
            vehicle: e.vehicle.id + ": " + e.vehicle.licenseNumber,
            km: e.vehicle.kilometres || 0,
            type: monitorLabels[e.type],
            serviceType: e.type,
            untilService: monitorInfo.isKmService ? (monitorInfo.untilService + "km") : daysUntilFormatter(monitorInfo.untilService / day2ms),
            status: e.status,
            estDate: monitorInfo.isKmService ? new Date(Date.now() + (monitorInfo.untilService / averageKmPerDay) * day2ms) : new Date(monitorInfo.nextService),
            estKm: monitorInfo.isKmService ? monitorInfo.untilService : undefined,
            driver: e.driver,
            vehicleId: e.vehicle.id,
            orderInfo: e.orderInfo ? JSON.parse(e.orderInfo) : undefined,
            vehicleType: e.vehicle.type,
            orderInfo2: e.orderInfo2 ? JSON.parse(e.orderInfo2) : undefined
        }
    })

    let vlist = [];

    // Add inspections
    mon.push(
        ...rows.map((e, i) => {

            if (vlist.includes(e.vehicle.id) || !e.vehicle.inspectionDate || !e.vehicle.inspectionByDate)
                return null;

            vlist.push(e.vehicle.id);

            const inspectionDate = new Date(e.vehicle.inspectionDate);
            const inspectionByDate = new Date(e.vehicle.inspectionByDate);
            return {
                id: dgInspectionIdOffset + i,
                serviceAt: format(inspectionByDate, "dd.MM.yyyy"),
                vehicle: e.vehicle.id + ": " + e.vehicle.licenseNumber,
                km: e.vehicle.kilometres || 0,
                type: monitorLabels[0],
                serviceType: 0,
                untilService: daysUntilFormatter((inspectionByDate.getTime() - Date.now()) / day2ms),
                driver: e.driver,
                status: 0,
                estDate: inspectionByDate,
                vehicleId: e.vehicle.id,
                orderInfo: e.orderInfo ? JSON.parse(e.orderInfo) : undefined,
                vehicleType: e.vehicle.type,
                licenseNumber: e.vehicle.licenseNumber,
                orderInfo2: e.orderInfo2 ? JSON.parse(e.orderInfo2) : undefined            
            }
        }).filter(e => e !== null)
    )

    // Sort
    mon.sort((a, b) => {
        return a.estDate.getTime() - b.estDate.getTime();
    })

    return mon;
}

/**
 * 
 * @param {{orderInfo?: any, users: any[]}} props 
 * @returns 
 */
const ServiceMonitorOrderedTooltip = (props) => {

    let user = null
    if (props.orderInfo) {
        user = props.users.find(e => e.id === props.orderInfo.orderer);
    }
 
    return <Table>
        <TableBody
            sx={{
                '& .MuiTableCell-root': {
                    color: 'primary.contrastText',
                }
            }}
        >
            <TableRow>
                <TableCell>
                    Pvm
                </TableCell>
                <TableCell>
                    {props.orderInfo && props.orderInfo.dateTime}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Tilaaja
                </TableCell>
                <TableCell>
                    {props.orderInfo && (user ? user.name : props.orderInfo.orderer)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Paikka
                </TableCell>
                <TableCell>
                    {props.orderInfo && props.orderInfo.place}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                    Lisätiedot
                </TableCell>
                <TableCell>
                    {props.orderInfo && props.orderInfo.details}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
}


export default function ServiceMonitor() {
    /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
    const userConfig = useUserConfig("serviceMonitor");
    const apiRef = useGridApiRef();

    const [monitorView, setMonitorView] = useState(/**@type {'list'|'cards'}*/(
        userConfig.config.viewType === 'list' ? 'list' : 
        userConfig.config.viewType === 'cards' ? 'cards' : 'list'
    ));

    const [monitors, setMonitors] = useState(/** @type {{ServiceMonitorDataGridType[]}} */([]));
    // Used for card view
    const [monitorsVehicle, setMonitorsVehicle] = useState(/** @type {import("../components/ServiceMonitorCard").ServiceMonitorVehicle[]} */([]));

    const [vehicleSelectList, setVehicleSelectList] = useState([]);

    const [count, setCount] = useState(0);

    const [showEditPopup, setShowEditPopup] = useState(false);
    const [editMonitor, setEditMonitor] = useState(null);

    const [sendInProgress, setSendInProgress] = useState(null); // id of monitor in progress

    const [cancelOrderMonitor, setCancelOrderMonitor] = useState(null);

    const [orderMonitor, setOrderMonitor] = useState(null);
    const [approveMonitor, setApproveMonitor] = useState(null);

    const [inspectionVehicle, setInspectionVehicle] = useState(null);

    // User list for getting user names
    const [users, setUsers] = useState([]);

    const filterMonitorsByVehicles = (monitors) => {
        if (vehicleSelectList.length === 0)
            return monitors;

        return monitors.filter(e => vehicleSelectList.find(y => y.id === e.vehicle.id) !== undefined)
    }

    const theme = useTheme();

    function orderService(monitor, cancel = false) {

        const mon = {
            id: monitor.id,
            orderInfo: monitor.orderInfo ? JSON.stringify(monitor.orderInfo) : undefined,
            status: cancel ? 0 : 1
        }

        setSendInProgress(monitor.id);

        LogitarApi.setServiceMonitor(mon, false, false)
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar(!cancel ? "Huolto tilattu" : "Huolto peruttu");
                    setCount(count + 1);
                }
                else {
                    enqueueSnackbar(!cancel ? "Huollon tilaus epäonnistui" : "Huollon peruminen epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar(!cancel ? "Huollon tilaus epäonnistui" : "Huollon peruminen epäonnistui", { variant: 'error' });
            })
            .finally(() => {
                setSendInProgress(null);
            })
        setOrderMonitor(null);
    }

    function approveService(monitor, info) {
        
        const mon = {
            id: monitor.id,
            approveInfo: info
        }
        setSendInProgress(monitor.id);

        LogitarApi.setServiceMonitor(mon, true, false)
            .then((response) => {
                if (response.status) {
                    enqueueSnackbar("Huolto kuitattu");
                    setCount(count + 1);
                    setApproveMonitor(null);
                }
                else {
                    enqueueSnackbar("Huollon kuittaus epäonnistui", { variant: 'error' });
                }
            })
            .catch((err) => {
                enqueueSnackbar("Huollon kuittaus epäonnistui", { variant: 'error' });
            })
            .finally(() => {
                setSendInProgress(null);
            });
    }

    // Can restore state after columns state resets
    const restoreDataGridInitialState = () => {
        setTimeout(() => {
            if (userConfig.config.dataGridInitialState && apiRef.current.restoreState) apiRef.current.restoreState(userConfig.config.dataGridInitialState);
        });
    }

    useEffect(() => {
        LogitarApi.getUsers()
            .then((res) => {
                setUsers(res.users);
                restoreDataGridInitialState();
            })
            .catch(err => {
                console.log(err);
            });
    }, [])

    useEffect(() => {
        switch (LogiTarUser.current.info.userType) {
            case LogiTarUserType.DRIVER:
                if (!LogiTarUser.current.vehicle)
                    break;

                console.log("Driver vehicle", LogiTarUser.current.vehicle)
                // Show only current vehicle
                LogitarApi.getServiceMonitor([LogiTarUser.current.vehicle.id, LogiTarUser.current.vehicle.trailer])
                    .then((res) => {
                        if (res.status) {
                            setMonitors(res.monitors);
                            setMonitorsVehicle(serviceMonitorResponseToCards(res.monitors));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                break;
            case LogiTarUserType.SERVICE:
            case LogiTarUserType.MANAGEMENT:
            case LogiTarUserType.ADMIN:
            case LogiTarUserType.BILLING:
            case LogiTarUserType.PLANNING:
                // Get all vehicles
                LogitarApi.getServiceMonitor()
                    .then((res) => {
                        if (res.status) {
                            setMonitors(res.monitors);
                            setMonitorsVehicle(serviceMonitorResponseToCards(res.monitors));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
                break;
            default:
                // Fetch no cars
                break;
        }
    }, [count]);

    const openEditPopup = (id) => {
        const mon = monitors.find(e => e.id === id);

        if (mon) {
            setEditMonitor(mon);
            setShowEditPopup(true);
        }
    }

    const openInspectionPopup = (vehicle) => {
        setInspectionVehicle(vehicle);
    }

    const getRowActions = (params) => {
        const editButton = <Tooltip title={params.id >= dgInspectionIdOffset ? "Muokkaa katsastusta" : "Muokkaa huoltoa"}>
            <GridActionsCellItem icon={<Edit />} onClick={() => {
                if (params.id >= dgInspectionIdOffset) {
                    openInspectionPopup(params.row.vehicleId);
                } else {
                    openEditPopup(params.id);
                }
            }} label={"Muokkaa huoltoa"} />
        </Tooltip>

        if (params.id >= dgInspectionIdOffset)
            return [
                editButton
            ];

        if (params.row.loading) {
            return [
                <GridActionsCellItem icon={<CircularProgress />} />
            ]
        }
        const buttons = params.row.status == 0 ?
            [
                <Tooltip title={"Tilaa huolto"}>
                    <GridActionsCellItem icon={<Assignment />} onClick={() => setOrderMonitor(params.row) /*orderService(params.row)*/} label={"Tilaa huolto"} />
                </Tooltip>
            ]
            :
            [
                <Tooltip title={"Kuittaa huolto"}>
                    <GridActionsCellItem icon={<AssignmentTurnedIn />} onClick={() => setApproveMonitor(params.row)} label={"Hyväksy huolto"} />
                </Tooltip>,
                <Tooltip title={"Peru huolto"}>
                    <GridActionsCellItem icon={<Cancel />} onClick={() => setCancelOrderMonitor(params.row)} label={"Peru huolto"} />
                </Tooltip>
            ];
        if (LogiTarUser.current.info.userType === LogiTarUserType.DRIVER) {
            return buttons;
        } else {
            buttons.unshift(editButton);
            return buttons;
        }
    }
    
    /** @type {import("@mui/x-data-grid").GridColDef<any>[]} */
    const columns = useMemo(() => [
        { field: "serviceAt", headerName: "Huolto viimeistään", flex: 1 },
        { field: "vehicle", headerName: "Kalusto", flex: 1 },
        { field: "km", headerName: "Kilometrit", flex: 1 },
        { field: "type", headerName: "Tyyppi", flex: 1 },
        { field: "untilService", headerName: "Päivää/km huoltoon", flex: 1 },
        {
            field: "status",
            headerName: "Huolto tilattu",
            flex: 1,
            renderCell: (p) => p.id >= dgInspectionIdOffset ?
                <></> :
                (p.row.status == 1 ?
                    <>
                        <Tooltip title={<ServiceMonitorOrderedTooltip orderInfo={p.row.orderInfo} users={users} />} >
                            <Box sx={{flex: 1}}>
                                <span>Tilattu</span>
                                <InfoOutlined sx={{height: '14px'}} />
                            </Box>
                        </Tooltip>
                    </>
                    :
                    <span>Ei tilattu</span>) 
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Muokkaa/tilaa/kuittaa",
            getActions: (params) => params.row.actions(params),
            flex: 1
        }

    ], [dgInspectionIdOffset, users]);

    return <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', height: '100%' }} className={"service-monitor"}>
        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ maxHeight: 48, display: 'flex', flexDirection: 'row' }}>
                <ToggleButtonGroup
                    value={monitorView}
                    exclusive
                    onChange={(e, v) => { if (v) {
                        setMonitorView(v);
                        userConfig.config = { ...userConfig.config, viewType: v };
                    }}}
                >
                    <ToggleButton value={'list'}><ViewListOutlined /></ToggleButton>
                    <ToggleButton value={'cards'}><GridView /></ToggleButton>
                </ToggleButtonGroup>
                {
                    !(LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ||
                        LogiTarUser.current.info.userType === LogiTarUserType.CONTRACTOR) &&
                    <Box>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setEditMonitor(null);
                                setShowEditPopup(true);
                            }}
                            sx={{ ml: 1, mt: 0.5 }}
                        >
                            <Add />
                        </Button>
                    </Box>
                }
            </Box>
            <Autocomplete
                sx={{
                    pl: 1,
                    minWidth: 200
                }}
                autoComplete={false}
                multiple
                id="vehicle-select"
                options={monitorsVehicle.map((e) => ({ id: e.vehicle.id, licenseNumber: e.vehicle.licenseNumber, trailer: e.vehicle.trailer }))}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                getOptionLabel={(opt) => `${opt.id}: ${opt.licenseNumber} ${(opt.trailer ? `(${opt.trailer})` : "")}`}
                renderInput={(params) => <TextField {...params} label="Kalustorajaus" autoComplete="off" />}
                value={vehicleSelectList}
                onChange={(e, v, r, opt) => {
                    const d = opt ? opt.option : undefined;
                    const nv = [...v];
                    if (d) {
                        if (r === 'selectOption') {
                            // New vehicle was added to the list
                            const trailer = monitorsVehicle.find(e => e.vehicle.id === d.trailer);
                            if (trailer) {
                                nv.push({
                                    id: trailer.vehicle.id,
                                    licenseNumber: trailer.vehicle.licenseNumber,
                                    trailer: null
                                });
                            }
                        }
                        else if (r === 'removeOption') {
                            // Remove trailer from nv
                            const trailerIndex = nv.findIndex(e => e.id === d.trailer);
                            if (trailerIndex >= 0) {
                                nv.splice(trailerIndex, 1);
                            }
                        }
                    }
                    setVehicleSelectList(nv);
                }}
            />


            { monitorView === 'cards' && (
                    <Box
                        sx={{ml: 5, mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', p: 1, overflowX: 'hidden' }}
                    >
                        <ColorInfo colors={[
                            { class: "row-default-green", info: "Huolto tilattu" },
                            { class: "row-default-yellow", info: "Huolto/katsastus lähellä" },
                            { class: "row-default-red", info: "Huolto/katsastus ylittynyt" },
                        ]} />
                    </Box>
            )}
        </Box>



        <Divider />
        {
            monitorView === 'list' &&
            <PersistentDataGridPro
                userConfig={userConfig}
                apiRef={apiRef}
                className="service-rows"
                columns={columns}
                density="compact"
                rows={serviceMonitorResponseToDataGrid(filterMonitorsByVehicles(monitors))
                    .map(row => row.id === sendInProgress ? { ...row, loading: true } : row)
                    .map(row => ({ ...row, actions: getRowActions}))
                }
                disableRowSelectionOnClick
                getRowClassName={(params) => {
                    let classes = "";
                    // Inspection or not driver = clickable
                    if (params.id >= dgInspectionIdOffset || LogiTarUser.current.info.userType !== LogiTarUserType.DRIVER) {
                        classes += "clickable";
                    }

                    if (params.row.status == 1) {
                        classes += " row-default-green";
                    }
                    else {
                        if ((params.row.estKm !== undefined && params.row.estKm < 0) || (params.row.estKm === undefined && params.row.estDate.getTime() < Date.now())) {
                            classes += " row-default-red";
                        }
                        else if ((params.row.estKm !== undefined && params.row.estKm < 10000) || (params.row.estKm === undefined && params.row.estDate.getTime() < Date.now() + (1000 * 3600 * 24 * 14))) {
                            classes += " row-default-yellow";
                        }
                        else {
                            classes += " service-normal";
                        }
                    }

                    return classes;
                }}
                slots={{
                    toolbar: () => <CustomToolbar userConfig={userConfig} hideButton hidePrinting customElement={<ColorInfo colors={[
                        { class: "row-default-green", info: "Huolto tilattu" },
                        { class: "row-default-yellow", info: "Huolto/katsastus lähellä" },
                        { class: "row-default-red", info: "Huolto/katsastus ylittynyt" },
                    ]} sx={{ ml: "auto", mr: 2 }} />} />
                }}
                localeText={localeText}
            />
        }
        {
            showEditPopup &&
            <ServiceMonitorPop
                data={editMonitor}
                vehicle={editMonitor ? editMonitor.vehicle : null}
                onClose={() => { setShowEditPopup(false); setCount(count + 1); }}
                allowedVehicles={LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ? [LogiTarUser.current.vehicle.id] : undefined}
                userList={LogiTarUser.current.info.userType === LogiTarUserType.DRIVER ? [LogiTarUser.current.info] : undefined}
                existingMonitors={monitors}
            />
        }

        {
            monitorView === 'cards' &&
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', p: 1, overflowX: 'hidden' }}>

                {
                    filterMonitorsByVehicles(monitorsVehicle).map((e, i) => {
                        return <ServiceMonitorCard
                            data={e}
                            key={i}
                            requestRefresh={() => setCount(count + 1)}
                            disableMonitorEdit={LogiTarUser.current.info.userType === LogiTarUserType.DRIVER}
                        />
                    })
                }
            </Box>
        }
        {
            !!cancelOrderMonitor &&
            <AlertPop
                title="Haluatko varmasti poistaa huollon tilauksen?"
                onClose={(a) => {
                    if (a) {
                        orderService(cancelOrderMonitor, true);
                    }
                    setCancelOrderMonitor(null);
                }}
                cancellable
            />
        }
        {
            !!orderMonitor &&
            <ServiceMonitorOrderPop
                row={orderMonitor}
                onClose={() => setOrderMonitor(null)}
                onSave={(o) => orderService(o)}
            />
        }
        {
            !!approveMonitor &&
            <ServiceMonitorApprovePop
                row={approveMonitor}
                onClose={() => setApproveMonitor(null)}
                onApprove={(o, info) => approveService(o, info)}
            />
        }
        {
            inspectionVehicle !== null &&
            <InspectionPop
                vehicle={inspectionVehicle}
                onClose={() => { setInspectionVehicle(null); setCount(count + 1) }}
            />
        }
    </Box>
}