import { Box, Button, CircularProgress, Popper, SvgIcon, Tooltip, Typography } from "@mui/material";
import { addDays, eachHourOfInterval, format, getDay, getWeek, intervalToDuration, subDays } from 'date-fns';
import LogitarApi from "../api/LogitarApi";
import Config from "../config/Config";

const workingHours = [[0, 6, "nightHours"], [6, 18, "dayHours"], [18, 22, "eveningHours"], [22, 24, "nightHours"]]

/**
 * @brief Calculate workhours
 * @param {{start: Date, end: Date}} interval
 * @param {null | Date} shiftDate Date on which the jobs are
 * @returns {{eveningHours: number, nightHours: number, dayHours: number, workHours: string|number, holidayHours: string|number, sundayHours: number|string}}
 */
export async function calculateWorkHours(interval, shiftDate = null) {
    return new Promise((resolve, reject) => {

        let holidays = []
        let workCalc = { eveningHours: 0, nightHours: 0, dayHours: 0, holidayHours: 0, sundayHours: 0 };

        LogitarApi.getHolidays().then((response) => {
            holidays = response.holidays
        })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {

                const workInterval = {
                    start: new Date(interval.start.setSeconds(0)),
                    end: new Date(interval.end.setSeconds(0))
                }

                let duration = intervalToDuration(workInterval)
                const hours = eachHourOfInterval(workInterval)

                //Counting hours 
                for (let i = 0; i < hours.length; i++) {

                    const timeSlot = workingHours.find((slot) => hours[i].getHours() >= slot[0] && hours[i].getHours() < slot[1])
                    if (i === 0) {
                        workCalc[timeSlot[2]] -= workInterval.start.getMinutes() * (1 / 60) * 1
                    }
                    if (i === hours.length - 1) {
                        workCalc[timeSlot[2]] += workInterval.end.getMinutes() * (1 / 60) * 1
                        continue
                    }
                    workCalc[timeSlot[2]] += 1
                }

                workCalc.workHours = `${((duration.hours + duration.days * 24) + (duration.minutes * (1 / 60) * 1)).toFixed(4)}`
                workCalc.nightHours = workCalc.nightHours.toFixed(4)
                workCalc.eveningHours = workCalc.eveningHours.toFixed(4)

                //Start or the end was not sunday, then check all of the holidays if the start or end is a holiday       
                for (const holiday of holidays) {
                    // If the start is a holiday and the shifts job are on the next day
                    if (holiday.date === format(workInterval.start, "yyyy-MM-dd") && 
                        shiftDate instanceof Date && 
                        (new Date(holiday.date).getDay()) === subDays(shiftDate, 1).getDay()) {
                            // The end might be also on a holiday, since this check must be done first, the end is checked also here
                            const isEndHoliday = holidays.find((h) => h.date === format(workInterval.end, "yyyy-MM-dd"))
                            if (isEndHoliday) {
                                workCalc.holidayHours = workCalc.workHours
                                break
                            }
                            workCalc.holidayHours = (24 - workInterval.start.getHours() - (workInterval.start.getMinutes() * (1 / 60) * 1)).toFixed(4)
                            break
                    }
                    if (holiday.date === format(workInterval.start, "yyyy-MM-dd")) {
                        workCalc.holidayHours = workCalc.workHours
                        break

                    } else if (holiday.date === format(workInterval.end, "yyyy-MM-dd")) {
                        workCalc.holidayHours = (workInterval.end.getHours() + (workInterval.end.getMinutes() * (1 / 60) * 1)).toFixed(4)
                        break
                    }
                }
                
                //If sunday then all hours are sunday hours
                if (!(['konnekuljetus'].includes(Config.getBranch()))) {
                    // Shifts jobs are on monday, but the driver starts shift early on sunday evening
                    if (shiftDate instanceof Date && getDay(workInterval.start) === 0 && getDay(shiftDate) === 1) {
                        workCalc.sundayHours = (24 - workInterval.start.getHours() - (workInterval.start.getMinutes() * (1 / 60) * 1)).toFixed(2)
                    } else if (getDay(workInterval.start) === 0) {
                        //Sunday hours overwrite holiday hours
                        workCalc.holidayHours = 0
                        workCalc.sundayHours = workCalc.workHours
                    } else if (getDay(workInterval.end) === 0) {
                        //If the start was not a holiday or sunday, then check if the end is on a sunday
                        //only hours from midnight until then end are sunday hours
                        workCalc.sundayHours = (workInterval.end.getHours() + (workInterval.end.getMinutes() * (1 / 60) * 1)).toFixed(2)
                        if (workCalc.holidayHours > 0) 
                            workCalc.holidayHours -= workCalc.sundayHours
                    }
                }

                resolve(workCalc)
            })
    })
}
/**
 * 
 * @param {{
 *  width?: string|number,
 *  height?: string|number,
 *  color?: string |null
 * }} param0 
 * @returns 
 */
export function Loader({ width = undefined, height = undefined, color = null }) {
    return (
        <Box sx={{ display: "flex", width: width || '100%', height: height || "40vh", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress color={"primary"} sx={{ color: color ? color : '' }} variant='indeterminate' disableShrink />
        </Box>
    )
}

export function getTime(params) {
    if (params || params === 0) {
        const decTime = Number(params)
        const hours = Math.floor(decTime)
        const minutes = Math.round((decTime - hours) * 60)
        return `${hours}:${minutes > 9 ? minutes : "0" + minutes}`
    }
}

export function CustomPopper(props) {
    return (<Popper {...props} style={{ p: 1, width: "auto" }} placement='bottom-start' />)
}

/**
 * @param {svg} icon Mui Icon inside button
 * @param {string} title Tooltip title
 * @param {boolean|undefined} disableInteractive Tooltip disableInteractive
 * @returns 
 */
export const TipButton = ({ title, icon, disableInteractive, ...rest }) => (
    <Tooltip title={title} placement="top" disableInteractive={disableInteractive}>
        <span>
            <Button {...rest}>
                <SvgIcon component={icon} />
            </Button>
        </span>
    </Tooltip>
)

export const formCargoName = (cargoObj) => {
    let cargoName = "";

    if (cargoObj.code !== null && cargoObj.code.length > 0) {
        cargoName += `${cargoObj.code} `;
    }

    if (cargoObj.name !== null && cargoObj.name.length > 0) {
        cargoName += cargoObj.name
    } else if (cargoObj.division !== null && cargoObj.division.length > 0) {
        cargoName += cargoObj.division
    }

    return cargoName
}

/**
 * @description WorkHour and WorkHourReport screen reports the same timeframe, this function calculates the correct one
 * @param {Date?} setToday 
 * @returns {{ from: Date, to: Date }}
 */
export const getPayTimeframe = (setToday = null) => {    
    const today = setToday ? setToday : new Date()
    const week = getWeek(today, { weekStartsOn: 1 })
    const day = ((today.getDay() + 6) % 7) + 1
    let from = new Date(today)
    let to = new Date(today)

    const correctWeek =  (week % 2 === 1)

    if (correctWeek) {
        from = subDays(from, day + 6)
        to = addDays(to, 7 - day)
    } else {
        from = subDays(from, day - 1)
        to = addDays(to, 14 - day)
    }

    return { from: from, to: to }
}

const isNumber = (decimal) => {
    return !isNaN(Number(decimal));
}

// Change desimal time to string (HH:MM:00)
export const decimalToTime = (decimal) => {
    if (isNumber(decimal)) {
        const num = parseFloat(decimal);
        if (isNaN(num) || num < 0) return ''; // Virheellinen syöte
        const hours = Math.floor(num);
        const minutes = Math.round((num - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    } else {
        return decimal;
    }
}

// Change desimal time to string (HH:MM)
export const decimalToTimeV2 = (decimal) => {
    if (isNumber(decimal)) {
        const num = parseFloat(decimal);
        if (isNaN(num) || num < 0) return ''; // Virheellinen syöte
        const hours = Math.floor(num);
        const minutes = Math.round((num - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    } else {
        return decimal;
    }
}

// Change text time (HH:MM:00) to decimal
export const timeToDecimal = (time) => {
    if (time) {
        const [hours, minutes] = time.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || minutes < 0 || minutes >= 60) {
        return ''; 
        }
        return (hours + minutes / 60).toFixed(2); 
    } else {
        return null;
    }
}
