import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LogitarItem } from "../api/LogitarApiTypes";
import { Check, Edit, Warning } from "@mui/icons-material";

export default function ItemNumberDuplicateError(props: { items: LogitarItem[], onCancel: () => void, onAccept: () => void }) {
    return (
        <Dialog
            open={props?.items?.length > 0}
            onClose={() => props?.onCancel()}
            maxWidth="lg"
        >
            <DialogTitle>
                Toisilla nimikkeillä on sama nimikenumero!
            </DialogTitle>
            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">ID</TableCell>
                            <TableCell>Nimikenumero</TableCell>
                            <TableCell>Nimike</TableCell>
                            <TableCell>Aktiivinen</TableCell>
                            <TableCell align="center">Muokkaa</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items && props.items.map((i) =>
                            <TableRow key={i.id}>
                                <TableCell align="right">{i.id}</TableCell>
                                <TableCell sx={{ color: "error.main" }}>{i.itemNumber}</TableCell>
                                <TableCell>{i.name}</TableCell>
                                <TableCell align="center">
                                    {i.active === 1 && <Check sx={{ mb: -0.75 }} />}
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        href={`/items?id=${i.id}`}
                                        target="_blank"
                                        variant="outlined"
                                        startIcon={<Edit />}
                                        size="small"
                                    >{i.id}</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props?.onCancel()}
                >
                    Peruuta
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    startIcon={<Warning />}
                    onClick={() => props?.onAccept()}
                >
                    Jatka silti
                </Button>
            </DialogActions>
        </Dialog>
    )
}