import { Box, Chip, FormGroup, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { PlanningJobInfo, PlanningItem, ItemFish, JobInfoJobFish } from "../misc/LogitarTypes";
import { fishReqs as itemFishRequires, reqs as itemRequires } from "../components/PlanningCarRow";
import { formatJobFishData } from "./fish/FishHelpers";
import { decimalToTimeV2} from '../misc/InternalFeatures'

export default function PlanningSlotTooltip({ job }: { job: PlanningJobInfo }) {
    const jobFishFormatted = formatJobFishData(job.jobFish);
    const isFishItem = job?.item?.itemType === "fish";
    const isPassive = job?.item?.active === 0;

    return (
        <Box sx={{ textAlign: "center" }}>
            <h2 style={{ textDecoration: isPassive ? "line-through" : undefined, margin: 2}}>
                {job.icItemAlias ? `${job.icItemAlias.id} - ${job.icItemAlias.name}: ${job.item.cargoLabel}` : `${job.item.id}: ${job.item.name} - ${job.item.cargoLabel}`}
                {isPassive && <Chip sx={{ mx: 1 }} label="Passiivinen" size="small" />}
            </h2>
            <FormGroup row={true} sx={{ justifyContent: "space-between", textAlign: "left"}}>
                <Typography variant="h6">
                    Auto: {job.vehicle}
                </Typography>
                <Typography variant="h6">
                    Kuorma nro: {job.slot + 1}
                </Typography>
            </FormGroup>
            <Table size="small" className="default-grey" sx={{ borderRadius: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Pyydetty</TableCell>
                        <TableCell>Täytetty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isFishItem && Object.keys(itemFishRequires).map((field: string, key) => {
                            const f = field as keyof ItemFish;
                            if (!job.item.itemFish[f]) {
                                return null;
                            }
                            const inJobF = itemFishRequires[field].inJob as keyof JobInfoJobFish;
                            const label = itemFishRequires[field].label;

                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={key + job.id + "f"}
                                >
                                    <TableCell>{label}</TableCell>
                                    <TableCell>{jobFishFormatted[inJobF]}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    {
                        Object.keys(itemRequires).map((field: string, key) => {

                            const f = field as keyof PlanningItem;

                            if (!job.item[f]) {
                                return null;
                            }
                            else if (f === "talenomVariants" && job.item[f].length < 1) {
                                return null;
                            }

                            const fInJ = itemRequires[field].inJob as keyof PlanningJobInfo
                            const label = itemRequires[field].label;

                            return (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={key + job.id}
                                >
                                    <TableCell>{label}</TableCell>
                                    {f === "talenomVariants" ?
                                        <TableCell>{job?.item?.talenomVariants?.find(v => v.product === job[fInJ])?.name}</TableCell>
                                        : f === "reqWaitTime" ?
                                            <TableCell>
                                                <Tooltip placement="right" title={job?.waitTimeReason}>
                                                    <span style={{ textDecoration: job?.waitTimeReason ? "underline" : undefined }}>{job[fInJ]}</span>
                                                </Tooltip>
                                            </TableCell>
                                        : f === "reqHours" ?
                                            <TableCell>{decimalToTimeV2(job[fInJ])}</TableCell> 
                                        :
                                            <TableCell>{job[fInJ]}</TableCell>
                                    }
                                </TableRow>
                            );
                        })
                    }
                    {/* {
                        job.talenomVariant &&
                        <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} 
                            key={job.id + "talenomVariant"}
                        >
                            <TableCell>Tuotenumero</TableCell>
                            <TableCell>{job.talenomVariant}</TableCell>
                        </TableRow>
                    } */}
                </TableBody>
            </Table>
        </Box>
    )
}